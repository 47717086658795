import React, { useState } from "react";
import { TicketProps } from "../../../../Helper/TicketInterface";
import { Box, HStack, Stack, Text } from "@chakra-ui/layout";
import style from "./EmergencyManagement.module.css";
import { Input } from "@chakra-ui/input";
import { Button } from "@chakra-ui/button";
import { AiOutlineSend } from "react-icons/ai";
// @ts-ignore
import ReactFileReader from "react-file-reader";
import { sendWhatsappMessage } from "../../Action/sendWhatsAppMsg";
import { useParams } from "react-router";
import { uploadFile } from "../../Action/uploadfile";
import { ticketUpdateById } from "../../../../Http";
import { sendMedicalPerk } from "../../Action/doctorPerk";
import { Select } from "@chakra-ui/react";
import { gethDoctorsRosterFun } from "../../Action/fetchDoctorsData";
import { getPocoDoctorsFun } from "../../Action/getPocodoctors";
import { sendMeetingLinkFun } from "../../Action/sendMeetingLink";
interface EmergencyManagementProps {
  data: TicketProps;
  getLatestTicketData: any;
  companyEmContacts: any;
}

interface mobileNumbersInterface {
  number: string,
  role: "patient" | "doctor"
}

interface MeetingPayloadInterface {
  beneficiaryId: string;
  uploadPrescriptionUrl: string;
  medicalInfoUrl: string;
  mobileNumbers?: mobileNumbersInterface[];
};

interface doctorRosterInterface {
  doctorId: string,
  doctorName: string,
  mobileNo: string,
  endTime: string,
  startTime: string,
  languages?: [],
  pocoDoctor?: boolean
}
const EmergencyManagement: React.FC<EmergencyManagementProps> = ({ data, getLatestTicketData, companyEmContacts }) => {
  const [doctorShared, setDoctorShared] = useState(
    data.doctorInfo ? true : false
  );
  const [prescriptionDocUrl, setPrescriptionDocUrl] = useState(
    data?.doctorInfo?.clinicalNotes[0]?.url || ""
  );
  const [isBtnLoading, setBtnIsLoading] = useState(false);
  const [doctorName, setDoctorName] = useState(
    data?.doctorInfo?.fullName || ""
  );
  const [doctorMobile, setDoctorMobile] = useState(
    data?.doctorInfo?.mobile || ""
  );
  const [uploading, setUploading] = useState(false);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sendMeetingLoading, setSendMeetingLoading] = useState<boolean>(false)
  let SaSToken = localStorage.getItem("sasToken");
  const handleViewCurrentDocument = () => {
    window.open(
      prescriptionDocUrl + "?" + SaSToken || data?.doctorInfo?.clinicalNotes[0]?.url + "?" + SaSToken,
      "_blank"
    );
  };

  const getEmergencyContact = (emList: any) => {
    // console.log("emList:", emList);
    const MessageToList = emList.map((el: any) => {
      return el.mobile || el.whatsappNumber;
    });
    return MessageToList.length ? MessageToList : [];
  };
  const handlePrescriptionSend = async () => {
    setBtnIsLoading(true);

    let emergencyContact = getEmergencyContact(
      data?.patient?.emergencyContacts || []
    );

    const WAPayloadBen = {
      template: "NOTIFY_EC_ON_DOCTOR_CONSULT",
      data: {
        to: [...emergencyContact, data.callerID || data.ticketRaisedBy?.mobile, data?.poc?.mobile, ...companyEmContacts],
        beneficiary: data?.patient?.fullName || "N/A",
      },
    };

    let waMessagePayload = {
      template: "NOTIFY_PATIENT_ON_PRESCRIPTION",
      data: {
        to: [
          ...emergencyContact,
          data.callerID || data.ticketRaisedBy?.mobile,
          data?.poc?.mobile,
        ],
        url: prescriptionDocUrl + "?" + SaSToken,
      },
    };
    sendWhatsAppMessageToEm(WAPayloadBen);
    sendWhatsAppMessageToEm(waMessagePayload);
  };
  const handleUpdatePrescriptionUrl = async (prescriptionUrl: string) => {
    const value = { url: prescriptionUrl, type: "img", tags: ["check"] };
    const ticketData = {
      ...data,
      doctorInfo: {
        ...data.doctorInfo,
        clinicalNotes: [...data?.doctorInfo?.clinicalNotes, value],
      },
    };
    await updateTicket(id, ticketData, "");
  };
  const handlePrescriptionDoc = async (files: any) => {
    setUploading(true);
    setPrescriptionDocUrl("uploading");
    try {
      let res = await uploadFile(
        data?.ticketRaisedBy?.benId,
        files,
        "doctorPrescription"
      );
      setPrescriptionDocUrl(res);
      setUploading(false);
      await handleUpdatePrescriptionUrl(res);
    } catch (error) {
      console.error(error);
      setUploading(false);
      setPrescriptionDocUrl("");
      alert("Some Technical Error from uploading PDF");
    }
  };
  const doctorPerk = async (benId: string) => {
    try {
      const res = await sendMedicalPerk(benId);
      if (res.signedUrl) {
        return res;
      } else {
        alert(
          "Some Technical issue in getting Doctor Perk"
        );
      }
    } catch (e: any) {
      console.log("error from ", e);
      alert(
        e?.response?.data?.message ||
        "Some Technical issue in getting Doctor Perk"
      );
      setBtnIsLoading(false);
    }
  };
  const handleProfileShare = async () => {
    if (!doctorName || !doctorMobile) {
      return alert("Please provide doctor name and mobile");
    }
    setIsLoading(true);
    // let agentDet:any= JSON.parse(localStorage.getItem("agentData")||"null");
    let ticketUpdatePayload = {
      ...data,
      doctorInfo: {
        fullName: doctorName,
        mobile: doctorMobile,
      }
      
    };
    ////Doctor Perk/////
    let getDoctorUrl;
    if (data?.ticketRaisedBy?.benId) {
      getDoctorUrl = await doctorPerk(data?.ticketRaisedBy?.benId);
    }

    const whatsappMessagePayload = {
      template: "NOTIFY_DOCTOR_ABOUT_PATIENT",
      data: {
        to: [doctorMobile],
        doctorName: doctorName,
        beneficiary: data?.ticketRaisedBy?.fullName || "N/A",
        patient: data?.patient?.fullName, //from
        beneficiaryAge: data?.patient?.age || "N/A",
        beneficiarySex: data?.patient?.sex || "N/A",
        isBeneficiaryConcious:
          data?.currentMedicalStatusResponse?.isPatientConscious,
        patientCondition:
          `${data?.currentMedicalStatusResponse?.currentCondition?.join(",") || "N/A"} Others: ${data?.currentMedicalStatusResponse?.otherDescription?.trim() || "N/A"}`,
        isAmbulanceRequested:
          data?.currentMedicalStatusResponse?.isAmbulanceRequired, //fromData
        poc: data?.poc?.fullName, //fromData
        pocNumber: data?.poc?.mobile, //fromData
        url: getDoctorUrl?.signedUrl?.split('?')[0] ? getDoctorUrl?.signedUrl?.split('?')[0] + "?" + SaSToken : "https://developmentbucket.blob.core.windows.net/pdfs/beneficiary-profile-not-getting-error-default-pdf.pdf",
      },
    };
    try {
      if (id) {
        const response = await updateTicket(
          id,
          ticketUpdatePayload,
          whatsappMessagePayload
        );
        alert("Patient details shared to doctor");
        if (response) {
          await getLatestTicketData();
          setDoctorShared(true);
        }
      }
    } catch (e) {
      setIsLoading(false);
      return alert("Some Technical Issue Updating ticket data");
    }
    // ShowAlert("success", "Patient details shared to doctor");
  };
  const sendWhatsAppMessageToEm = async (payload: any) => {
    try {
      const response = await sendWhatsappMessage(payload);
      if (response) {
        setIsLoading(false);
        setBtnIsLoading(false);
        // alert("Patient details shared to doctor")
      }
    } catch (e: any) {
      setIsLoading(false);
      setBtnIsLoading(false);
      alert(e?.response?.data?.message || "Some Technical Issue sending whatsapp msg");
    }
  };
  const updateTicket = async (
    id: any,
    ticketPayload: any,
    waMessagePayload: any
  ) => {
    try {
      if (id) {
        const response = await ticketUpdateById(id, ticketPayload);
        if (response) {
          if (waMessagePayload !== "") {
            sendWhatsAppMessageToEm(waMessagePayload);
          }
          // alert("Emergency Resolved")
          return response;
        }
      }
    } catch (e: any) {
      console.error("Some Technical Issue sending whatsapp msg");
      setIsLoading(false);
      return alert(e?.response?.data?.message || "Some Technical Issue sending whatsapp msg");
    }
  };
  const [selectedDoctor, setSelectedDoctor] = useState<doctorRosterInterface | null>(null)
  const [roster, setRoster] = useState<doctorRosterInterface[] | []>([])
  const [inputPatientMobiles, setInputPatientMobiles] = useState<string[] | []>([])
  const [inputDoctorMobiles, setInputDoctorMobiles] = useState<string[] | []>([])

  const handleDoctorConsult = async () => {
    let roster = await gethDoctorsRosterFun()
    let pocoDoctors: [] | object[] = await getPocoDoctorsFun()
    let hospitalContacts = data?.hospitalInfo?.hospitalContacts?.filter((item) => item.contact?.length > 0 && item.designation.toLowerCase() === "doctor")

    let emergencyDoctorContacts: any = []
    hospitalContacts?.forEach((item) => {
      let number = null
      for (let i = 0; i < item?.contact?.length; i++) {
        if (item?.contact[i]?.length === 10 && !isNaN(item?.contact[i])) {
          number = item.contact[i]
          break
        }
      }
      if (number) {
        emergencyDoctorContacts.push({
          doctorId: item.doctorId || Math.random.toString(),
          doctorName: item.name,
          mobileNo: number,
          endTime: item.endTime,
          startTime: item.startTime,
        })
      }
    })

    let pocoDoctorsArray = pocoDoctors?.length > 0 ? pocoDoctors.map((doctor: any) => {
      return {
        doctorId: doctor.doctorId,
        doctorName: doctor.name,
        mobileNo: doctor.mobileNo,
        endTime: doctor.timeSlots.endTime,
        startTime: doctor.timeSlots.startTime,
        pocoDoctor: true
      }
    }) : []
    let combinedRosters: any = []
    if (roster) {
      combinedRosters.push(...roster)
    }
    if (emergencyDoctorContacts) {
      combinedRosters.push(...emergencyDoctorContacts)
    }
    if (pocoDoctorsArray) {
      combinedRosters.push(...pocoDoctorsArray)
    }
    setRoster(combinedRosters)
  }

  const handleSelectedDoctor = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let doctorId = e.target.value
    let doctor = roster.filter((item) => item.doctorId === doctorId)
    setSelectedDoctor(doctor[0])

  }

  const handlePatientMobileNo = (e: React.ChangeEvent<HTMLInputElement>) => {
    let number = e.target.value.trim().split(",")
    let trimmedNumbers = number.map((item) => item.trim())
    let numberArr = trimmedNumbers.filter((item) => item !== '')
    setInputPatientMobiles(numberArr.length > 0 ? numberArr : [])
  }

  const handleDoctorMobileNo = (e: React.ChangeEvent<HTMLInputElement>) => {
    let number = e.target.value.trim().split(",")
    let trimmedNumbers = number.map((item) => item.trim())
    let numberArr = trimmedNumbers.filter((item) => item !== '')
    setInputDoctorMobiles(numberArr.length > 0 ? numberArr : [])
  }

  const sendMeetingLink = async () => {
    setSendMeetingLoading(true)
    if (!selectedDoctor && inputDoctorMobiles.length <= 0) {
      alert("Please select doctor or add custom doctor numbers")
      setSendMeetingLoading(false)
      return
    }

    let isNumberInvalid = false

    if (inputPatientMobiles.length > 0) {
      inputPatientMobiles.forEach((item) => {
        if (isNumberInvalid) {
          return
        }
        if (!Number(item) || item.length !== 10) {
          isNumberInvalid = true
          alert("Please Provide Valid Patient Numbers")
          setSendMeetingLoading(false)
          return
        }
      })
    }

    if (inputDoctorMobiles.length > 0) {
      inputDoctorMobiles.forEach((item) => {
        if (isNumberInvalid) {
          return
        }
        if (!Number(item) || item.length !== 10) {
          isNumberInvalid = true
          alert("Please Provide Valid Doctor Numbers")
          setSendMeetingLoading(false)
          return
        }
      })
    }

    if (isNumberInvalid) {
      setSendMeetingLoading(false)
      return
    }
    let payload: MeetingPayloadInterface = {
      beneficiaryId: data.ticketRaisedBy.benId,
      uploadPrescriptionUrl: "https://dev.apis.pococare.com/azure/api/v1/signedUrl/generateSignedUrl",
      medicalInfoUrl: `https://dev.apis.pococare.com/api/v1/beneficiary/medical-info-perk/${data.ticketRaisedBy.benId}`,
    }
    let mobileNumbers: mobileNumbersInterface[] = []
    if (data.patient.mobile) {
      mobileNumbers.push({ number: data.patient.mobile, role: "patient" })
    }
    if (data.ticketRaisedBy.mobile) {
      mobileNumbers.push({ number: data.ticketRaisedBy.mobile, role: "patient" })
    }
    if (selectedDoctor) {
      mobileNumbers.push({ number: selectedDoctor.mobileNo, role: "doctor" })
    }
    if (inputPatientMobiles.length > 0) {
      let patientMobileArr: mobileNumbersInterface[] = inputPatientMobiles.map((number) => { return { number: number, role: "patient" } })
      mobileNumbers.push(...patientMobileArr)
    }
    if (inputDoctorMobiles.length > 0) {
      let doctorMobileArr: mobileNumbersInterface[] = inputDoctorMobiles.map((number) => { return { number: number, role: "doctor" } })
      mobileNumbers.push(...doctorMobileArr)
    }

    payload["mobileNumbers"] = mobileNumbers
    // eslint-disable-next-line
    let response = await sendMeetingLinkFun(payload)
    setSendMeetingLoading(false)
    if (response) {
      alert("MeetingLink Sent Successfully")
    }
  }

  return (
    <>
      <Box className={style.wrapper}>
        <Stack
          mt={"1rem"}
          className={style.card}
          direction={"column"}
          justify={"flex-start"}
        >
          <Box>
            <Text textAlign={"center"} className={style.subTitle}>
              Doctor Consult
            </Text>
          </Box>

          <Box>
            <Select placeholder="Select Doctor" onClick={handleDoctorConsult} onChange={handleSelectedDoctor}>
              {roster?.map((doctor) => {
                if (doctor.pocoDoctor) {
                  return <option value={doctor.doctorId}>{`${doctor.doctorName} ${doctor.startTime} - ${doctor.endTime} PocoDoctor`}</option>
                } else {
                  return <option value={doctor.doctorId} style={{ fontWeight: "bold" }}>{`${doctor.doctorName} ${doctor.startTime} - ${doctor.endTime}`}</option>
                }
              })}
            </Select>
            {selectedDoctor &&
              <Box fontWeight={"bold"} mt={"20px"}>
                <Text>Doctor Name: {selectedDoctor.doctorName}</Text>
                <Text>Doctor Mobile: {selectedDoctor.mobileNo}</Text>
                <Text>Doctor StartTime: {selectedDoctor.startTime}</Text>
                <Text>Doctor EndTime: {selectedDoctor.endTime}</Text>
              </Box>
            }
            <Box mt={"20px"}>
              <label>Doctor Numbers</label>
              <Input placeholder="Add Custom Numbers Separated By Comma" onChange={handleDoctorMobileNo}></Input>
            </Box>
            <Box mt={"20px"}>
              <label>Patient Numbers</label>
              <Input placeholder="Add Custom Numbers Separated By Comma" onChange={handlePatientMobileNo}></Input>
            </Box>
            <Box className={style.gridSystem}  >
              {doctorShared ? (
                <HStack
                  p={10}
                  justifyContent={"space-between"}
                >
                  <Box>
                    <Text color={"#2f2e2e"} fontWeight={"500"}>
                      <Text color={"black"} as="span">
                        {" "}
                        Doctor Name:{" "}
                      </Text>{" "}
                      {doctorName || data?.doctorInfo?.fullName || ""}
                    </Text>
                    <Text color={"#2f2e2e"} fontWeight={"500"}>
                      <Text color={"black"} as="span">
                        {" "}
                        Contact:{" "}
                      </Text>{" "}
                      {doctorMobile || data?.doctorInfo?.mobile || ""}
                    </Text>
                  </Box>
                  <Box textAlign={"center"}>
                    {prescriptionDocUrl ||
                      data?.doctorInfo?.clinicalNotes[0]?.url ? (
                      <>
                        <Button
                          isLoading={uploading ? true : false}
                          loadingText="Uploading"
                          colorScheme="teal"
                          variant="outline"
                          marginTop={"10px"}
                          onClick={handleViewCurrentDocument}
                        >
                          View Document
                        </Button>
                        <Button
                          rightIcon={<AiOutlineSend />}
                          marginLeft={"10px"}
                          isDisabled={
                            data?.doctorInfo.clinicalNotes.length
                              ? true
                              : false
                          }
                          isLoading={isBtnLoading} // need to make dynamic
                          colorScheme="teal"
                          variant="outline"
                          marginTop={"10px"}
                          onClick={handlePrescriptionSend}
                        >
                          Send
                        </Button>
                      </>
                    ) : (
                      <ReactFileReader
                        fileTypes={[".pdf"]}
                        base64={true}
                        multipleFiles={false}
                        handleFiles={handlePrescriptionDoc}
                      >
                        <Text color={"red"}>Upload Only PDF</Text>
                        <Button marginTop={"10px"}> Upload Document</Button>
                      </ReactFileReader>
                    )}
                  </Box>
                </HStack>
              ) : (
                <Box display={"flex"} alignItems={"center"}>
                  {/* <Text className={style.subTitle}>Doctor consultation</Text> */}
                  <Box mt={"1rem"} className={style.flex} >
                    <Box w={"40%"}>
                      <Input
                        value={doctorName}
                        onChange={(e) => setDoctorName(e.target.value)}
                        placeholder={"Doctor Name"}
                      />
                    </Box>
                    <Box w={"40%"}>
                      <Input
                        value={doctorMobile}
                        onChange={(e) => {
                          const inputMobile = e.target.value;
                          // Remove any non-digit characters from the input
                          const cleanedMobile = inputMobile.replace(/\D/g, "");

                          // Ensure that the cleanedMobile doesn't exceed ten digits
                          if (cleanedMobile.length <= 10) {
                            setDoctorMobile(cleanedMobile);
                          }
                        }}
                        placeholder={"Mobile Number"}
                      />
                    </Box>
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Button
                        isDisabled={data?.ticketStatus === "New" || data?.ticketStatus === "Completed" ? true : false}
                        onClick={handleProfileShare}
                        isLoading={isLoading}
                        // mt={"1rem"}
                        colorScheme="green"
                      >
                        Share Patient Profile
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            <Button mt={"20px"} onClick={sendMeetingLink} isDisabled={data?.ticketStatus === "New" || data?.ticketStatus === "Completed" ? true : false} colorScheme="green" isLoading={sendMeetingLoading}>Send Meeting Link</Button>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default EmergencyManagement;
