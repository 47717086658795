import style from "./card.module.css"

interface InsuranceProps{
    cardData:any
}
export const CardWrapper:React.FC<InsuranceProps>=({cardData})=>{
    let SaSToken = localStorage.getItem("sasToken");
    return(
        <>
        <div className={style.wrapper}>
           {
            Object.entries(cardData).map(([key,value]:any)=>{
                return(
                    <p>
                      {key!=="docUrl"&&key!=="_id"&&key!=="expiry"&&<>
                      <strong>{key}:</strong> {value}
                      </>  }
                        {key === "docUrl" && <>
                        <strong>{key}:</strong>
                        {
                            value!==""? <a  target="blank" href={value+"?"+SaSToken}>Open</a>:<>N/A</>
                        }
                       
                        </> }
                    </p>
                )
            })
           }

        </div>

        </>
    )
}