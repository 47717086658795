import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Image,
  Input,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { agentPwdResetAction } from "./Action/AgentLoginAction";
import { useNavigate } from "react-router-dom";

const ResetPwd = () => {
  const navigate = useNavigate();
  const [loading,setLoading]=useState<boolean>(false);
  const [newPassword, setNewPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (newPassword.confirmPassword !== newPassword.newPassword) {
        setLoading(false);
        return alert("New password and confirm password not same");
      } else {
        setLoading(true);
        let agent = localStorage.getItem("agentData");
        let loginStatus = localStorage.getItem("isLogin");
        
        if (agent && loginStatus) {
          let parsedUser = JSON.parse(agent);
          await agentPwdResetAction(
            { password: newPassword.confirmPassword },
            parsedUser.userId
          );
          alert(
            "Your password has been successfully change you can relogin with new credentials"
          );
          localStorage.clear();
          setLoading(false);
          navigate("/login");
        }
      }
    } catch (error: any) {
      setLoading(false);
      console.log("Error: ",error?.response);
      if(error.response.data.statusCode===409){
        return alert(error?.response?.data?.message);
      }else{
        return alert(
          error?.response?.data?.message?.map((msg: any) => msg).join(" ")
        );
      }
    }
  };
  const handleChange = (e: any) => {
    e.preventDefault();
    setNewPassword((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };
  return (
    <Box
      boxShadow="2xl"
      borderRadius={"10px"}
      h={"auto"}
      width={{ md: "30%", base: "100%" }}
      margin={"auto"}
      padding={9}
    >
      <Box mt={5} display={"flex"} justifyContent={"space-around"}>
        <Image
          w={"40%"}
          src="https://pococare.com/Images/logo.png"
          alt="Dan Abramov"
        />
      </Box>

      <Box minWidth={"70%"} margin={"auto"} mt={7} mb={10}>
        <FormControl>
          <FormLabel mt={4}>Enter New Password</FormLabel>
          <Input
            name="newPassword"
            onChange={(e) => handleChange(e)}
            type="password"
            required
            value={newPassword.newPassword || ""}
            placeholder="New Pass : Mypwd@79966"
          />
          <FormLabel mt={3}>Confirm Password</FormLabel>
          <Input
            name="confirmPassword"
            onChange={(e) => handleChange(e)}
            required
            value={newPassword.confirmPassword || ""}
            type="password"
            placeholder="Confirm Pass : Mypwd@79966"
          />
        </FormControl>
        <Box mt={2}>
          <Text color={"red"} fontSize="xs">
            Note: Password must contain 1 alphabet either uppercase or
            lowercase, 1 special character, 1 number, and length should be 8 or
            more. Example: Mypwd@79966
          </Text>
        </Box>
        <Box mt={6}>
          <Button
            onClick={handleSubmit}
            color={"white"}
            bgColor={"#f53395"}
            isLoading={loading}
            loadingText={"Loading.."}
            width={"full"}
          >
            Reset Password
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default ResetPwd;
