import { Box } from "@chakra-ui/react";
import { CustomModal } from "../../../GlobalComponents/CustomModal/CustomModal";
import { CustomInput } from "../../../GlobalComponents/CustomInput/CustomInput";
import React, { useState } from "react";
import { CustomButton } from "../../../GlobalComponents/CustomButton/CustomButton";
interface PocModalWrapperProps {
  handleCloseModal: () => void;
  updatePocData: any;
}
export const PocModalWrapper: React.FC<PocModalWrapperProps> = ({
  handleCloseModal,
  updatePocData,
}) => {
  const [pocDetails, setPocDetails] = useState({
    fullName: "",
    mobile: "",
  });
  //eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (updatedField: { label: string; value: string }) => {
    setPocDetails({
      ...pocDetails,
      [updatedField.label]: updatedField.value,
    });
  };

  const handleUpdateTicket = async () => {
    try {
      if (!pocDetails.fullName || !pocDetails.mobile) {
        alert("Please enter the poc details");
      } else if(pocDetails.mobile.length!==10){
        alert("Mobile number should be of 10 digits");
      }
      else {
        setIsLoading(true);
        let payload={
            poc:pocDetails
        }
        await updatePocData(payload);
        handleCloseModal();
        setIsLoading(false);
      }
    } catch (e: any) {
      setIsLoading(false);
      alert(
        e?.response?.data?.message || "Some Technical Issue in getting Company"
      );
    }
  };
  return (
    <>
      <CustomModal modalTitle="PocEdit" handleCloseModal={handleCloseModal}>
        <Box width={"100%"} display={"flex"} justifyContent={"space-between"}>
          {Object.entries(pocDetails).map(([key, value]) => (
            <React.Fragment key={key}>
              <CustomInput label={key} onChange={handleChange} value={value} />
            </React.Fragment>
          ))}
         
        </Box>
        <Box  marginTop={"1rem"} >
            <CustomButton
              label="update"
              customStyle={""}
              handleClick={handleUpdateTicket}
            />
          </Box>
        
      </CustomModal>
    </>
  );
};
