import {  getBenByMobile } from "../../../Http";
//need to refactor this api call///
export const getBeneficiaryDataByMobile = async (mobile: string) => {
  try {
    const { data } = await getBenByMobile(mobile);
    // console.log("data:", data);
    if (data.data.length) {
      return data.data;
    }else{
      return[];
    }
  } catch (e: any) {
    // return alert(e?.response?.data?.message || "Some Technical Issue");
    throw e;
    // return e.response?.data;
  }
};
