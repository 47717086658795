import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@chakra-ui/react";

interface CallDetailsPopupsProps {
  data: any;
  handleCloseCallDetailsModal:any
}

export const CallDetailsPopup: React.FC<CallDetailsPopupsProps> = ({
  data,
  handleCloseCallDetailsModal
}) => {
  const {  onClose } = useDisclosure();
  const handleClose=()=>{
    handleCloseCallDetailsModal(); 
    onClose();
  }
  console.log('data:', data);
  return (
    <>
      <Modal isOpen={true} onClose={handleClose}>
        {/* <ModalOverlay /> */}
        <ModalContent>
          <ModalHeader>CallDetails</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {Object.entries(data).map((details: any, index: number) => {
              return (
                <>
                  <div style={{display:"flex", width:"100%"}}>
                    <div style={{width:"40%",border:"1px solid black",padding:"1rem"}}>{details[0]}</div>
                    <div style={{width:"50%",border:"1px solid black",padding:"1rem"}}>{details[1]||"N/A"}</div>
                  </div>
                </>
              );
            })}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
