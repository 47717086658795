import { outBoundCallList } from "../../../Http"



export const buildQueryForOutBoundCall=(queryData:any)=>{
    // console.log('queryData:', queryData);
    const {to,from,pageSize,pageNumber}=queryData;
    let url="";
    if(to!==""){
        url+=`to=${to}&`
      }
      if (from !== "") {
        url += `from=${from}&`;
      }
    //   if (status !== "") {
    //     url += `ticketStatus=${status}&`;
    //   }
      if (pageSize) {
        url += `pageSize=${pageSize}&`;
      }
      if (pageNumber) {
        url += `pageNumber=${pageNumber}&`;
      }
      
      // Remove the trailing '&' if it exists
      if (url.endsWith("&")) {
        url = url.slice(0, -1);
      }
      console.log('url:', url);

      return url;

}

export const getListOfOutBoundCall=async (agentName:string)=>{
    try{
        // console.log('agentName:', agentName);
    const res=await outBoundCallList(agentName);
    localStorage.setItem("callHistory",JSON.stringify(res.data));
    return res.data;
    }catch(e){
      console.log('e:', e);
        throw e;

    }
}