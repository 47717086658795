import { Box, Stack, Text } from "@chakra-ui/react";
import React from "react";
import style from "../GlobalCss/Card.module.css";
import { HiOutlinePencilSquare } from "react-icons/hi2";
interface CardProps {
  title: string;
  name: string;
  mobileNumber: string;
  company: string;
  handleUpdate?:any
  ticketData?:any
}
const NameCard: React.FC<CardProps> = ({
  title,
  name,
  mobileNumber,
  company,
  handleUpdate,
  ticketData
}) => {
  // console.log('ticketData:', ticketData);
  return (
    <Box className={style.wrapper}>
      <Stack
        className={style.card}
        mt={"1rem"}
        direction={"column"}
        justify={"flex-start"}
      >
        <Box>
          <Text className={style.subTitle}>{title}</Text>
        </Box>
        <Stack direction={"row"} spacing={"1rem"} align={"center"}>
          {/* <Text className={style.text_style}>Name:</Text> */}
          <Text className={style.text_style_bold}>{name}</Text>
        </Stack>
        <Stack direction={"row"} spacing={"1rem"} align={"center"}>
          {/* <Text>Phone :</Text> */}
          <Text className={style.text_style_bold}> {mobileNumber}</Text>
        </Stack>
        <Stack direction={"row"} spacing={"1rem"} align={"center"}>
          {/* <Text>Phone :</Text> */}
          <Text className={style.text_style_bold}> {company || ""}</Text>
        </Stack>
    
      </Stack>
      {ticketData?.ticketStatus !== "Completed"&&title === "POC" && name !=="Poc Not Selected" &&
      <Box className={style.editIcon}>
        <HiOutlinePencilSquare onClick={handleUpdate} color={"#008470"} fontSize={"23px"} />
      </Box>
      }
    </Box>
  );
};

export default NameCard;
