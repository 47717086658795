import { getDoctorsRoster } from "../../../Http"

export const gethDoctorsRosterFun = async () => {
    try {
        let now = new Date();
        let dayIndex = now.getDay();
        let daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        let currentDay = daysOfWeek[dayIndex];
        let response = await getDoctorsRoster(currentDay)
        return response.data
    } catch (error: any) {
        console.log(error)
        if (error.response?.status !== 404) {
            alert(error.response?.data?.message || "Issue while getting doctors data")
        }
    }
}