import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ReactNode } from "react";

interface CustomModalProps {
  modalTitle: string;
  handleCloseModal: () => void;
  children?: ReactNode;
}

export const CustomModal: React.FC<CustomModalProps> = ({
  modalTitle,
  handleCloseModal,
  children,
}) => {
  return (
    <>
      <Modal size={"md"} isOpen={true} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalTitle}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{children}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
