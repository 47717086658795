import { addressAutoComplete, addressFromPlacid, getLatLon } from "../../../Http";

export const geoCode = async (payload:any) => {
  // const apiKey = "AIzaSyCyMav3OTEct_1qw15ZeqxTmdnyS6onYD8";
  // try {
  //   const address = encodeURIComponent(payload);
  //   const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyCyMav3OTEct_1qw15ZeqxTmdnyS6onYD8`;
  //   const response = await fetch(url);
  //   const data = await response.json();
  //   // console.log("data:", data);
  //   if (data.status === "OK" && data.results.length > 0) {
  //     const result = data.results[0];
  //     const lat = result.geometry.location.lat;
  //     const lng = result.geometry.location.lng;
  //     return { lat, lng };
  //   } else {
  //     // throw new Error("Geocoding failed");
  //     // alert("Error geocoding address please Enter Proper Address");
  //   }
  // } catch (error) {
  //   alert("Error geocoding address:");
  //   throw error;
  // }
  // console.log('payload:#######################', payload)

  const newPayload={
    address1:payload.addressLine1||"",
    address2:payload.addressLine2||"",
    city:payload.city||"",
    state:payload.state||"",
    country:payload.country||"",
    pincode:payload.pinCode||""
  }
  try{
    const res=await getLatLon(newPayload);
    const data=res.data;
    if (data.results && data.results.length > 0) {
      const { lat, lng } = data.results[0].geometry.location;
      return { lat, lng };
    } else {
      throw new Error('No results found');
    }

  }catch(e){
    alert("Error geocoding address:");
      throw e;
  }
};


export const autoCompletionResult=async(payload:any)=>{
  try{
    const res=await addressAutoComplete(payload);
   return res.data;    
  }catch(e){
    alert("Error geocoding address:");
      throw e;
  }
}

export const getFullAddress=async(placeId:string)=>{
  try{
    const res=await addressFromPlacid(placeId);
    return res.data;
  }catch(e){
    alert("Error from getting address:");
      throw e;
  }
}
