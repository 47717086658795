import React, { useEffect, useState } from "react";
import SideBar from "../../GlobalComponents/Sidebar/SideBar";
import style from "./Dashboard.module.css";
import { Box, Card, Text } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { getTicket } from "../Ticket/Action/getTicketById";
import CallSection from "./Component/CallSection/CallSection";
import PersonalDetails from "./Component/PersonalDetails/PersonalDetails";
import {
  EmergencyContact,
  TicketProps,
  TicketUpdateInterface,
} from "../../Helper/TicketInterface";
import BeneficiaryDetails from "./Component/BeneficiaryDetails/BeneficiaryDetails";
import PatientDetails from "./Component/PateintDetails/PatientDetails";
import { getBeneficiaryData } from "./Action/getAllBeneficiary";
import { getSingleBenDetails } from "./Action/getBeneficiary";
import { Address, BenDataProps } from "../../Helper/BeneInterface";
import MedicalDetails from "./MedicalDetails/MedicalDetails";
import PickupLocation from "./Component/PickupLocation/PickupLocation";
import PreferredHospital from "./Component/PreferredHospital/PreferredHospital";
// import { getNearbyHospitals } from "./Action/getNearbyHospital";
import NearByHospital from "./Component/NearByHospital/NearByHospital";
import { geoCode } from "./Action/geoCode";
// import { Hospital } from "../../Helper/HospitalInterface";
// import { getAllHospital } from "./Action/getAllHospital";
import PointOfContact from "./Component/PointOfContact/PointOfContact";
import EmergencyManagement from "./Component/EmergencyManagement/EmergencyManagement";
import AmbulanceMessage from "./Component/AmbulanceMessage/AmbulanceMessage";
import AmbulanceTracking from "./Component/AmbulanceMessage/AmbulanceTracking";
// import CallerPopup from "./Component/CallerPopups/CallerPopus";
// import { findClosestAddress } from "./Action/distanceMatrix";
import { getNearbyHospitals } from "./Action/getNearbyHospital";
import { getCallLogsByTicketId } from "./Action/getCallLogs";
import { getAllCompany } from "./Component/PateintDetails/Action/getAllCompany";
import { getInternalPocoTeamDetails } from "./Component/CallerPopups/action/action";
import { InsuranceDetails } from "./Component/InsuranceDetails/InsuranceDetails";
import { updateTicket } from "./Action/updateTicket";
import { PocModalWrapper } from "./PocModalWrapper/PocModalWrapper";
import { findClosestAddress } from "./Action/distanceMatrix";
// import { findClosestAddress } from "../../Http";
// import { getNearbyHospitals } from "./Action/getNearbyHospital";
export interface patientProps {
  id: string;
  fullName: string;
  mobile: string;
  whatsappNumber: string;
  email: string;
  age: string;
  sex?: string;
  languages: string[];
  emergencyContacts: EmergencyContact[];
  address: any[];
}
interface preferredHospitalProps {
  hospitalName: string;
  distance: string;
  id: string;
}
const Dashboard: React.FC = () => {
  const navigate = useNavigate();

  const params = useParams<{ id: string }>();
  const [data, setData] = useState<TicketProps>();
  const [pocName, setPocName] = useState("");
  const [pocNumber, setPocNumber] = useState("");
  const [agent, setAgent] = useState("null");
  const [beneficiaryList, setBeneficiaryList] = useState<BenDataProps[]>([]);
  const [patientDetails, setPatientDetails] = useState<patientProps>();
  const [patientName, setPatientName] = useState(
    data?.patient?.fullName || "Patient Not Selected"
  );
  const [patientNumber, setPatientNumber] = useState("Patient Not Selected");
  const [preferredHospitalData, setPreferredHospitalData] = useState<
    preferredHospitalProps[] | []
  >([]);
  //eslint-disable-next-line
  const [checkAmbulanceDoctorReq, setCheckAmbulanceDoctorReq] = useState(false);
  //eslint-disable-next-line
  const [hospitalLists, setHospitalList] = useState<any>([]);
  const [addressData, setAddressData] = useState<Address[]>([]);
  // const [showModal, setShowModal] = useState<boolean>(false);
  const [hospitalContact, setHospitalContact] = useState<string>("");
  const [callLogsList, setCallLogList] = useState<any[]>([]);
  const [companyEmContacts, setCompanyEmContacts] = useState<any[]>([]);
  const [companyNames, setCompanyNames] = useState<any[]>([]);
  //eslint-disable-next-line
  const [pocoTeamData, setPocoTeamData] = useState<any>([]); // const [render,setRender]=useState(false);
  const [ticketData, setTicketData] = useState<TicketUpdateInterface>({
    currentMedicalStatusResponse: {
      isItEmergency: "Yes",
      isAmbulanceRequired: "Yes",
      isDoctorConsultationRequired: "Yes",
      isPatientConscious: "Yes",
      currentCondition: [""],
      otherDescription: "",
    },
  });
  const [showPocModal, setShowPocModal] = useState(false);
  const [insuranceData, setInsuranceData] = useState<any>([]);
  // console.log('ticketData:', ticketData)
  const createPatientDetails = (fullName: string, mobile: string) => {
    setPatientName(fullName);
    setPatientNumber(mobile || "Not Found");
  };
  //dynamic AgentName
  const agentName = (name: string) => {
    setAgent(name || data?.agent?.fullName || "");
  };
  const setPocDetails = () => {
    if (data) {
      if (data.poc || data.ticketStatus === "Work in progress") {
        createPocPayload(data.poc);
      }
    }
  };
  //buildPocPayload
  const createPocPayload = (pocDetails: {
    fullName: string;
    mobile: string;
  }) => {
    setTicketData({
      ...ticketData,
      poc: {
        fullName: pocDetails?.fullName,
        mobile: pocDetails?.mobile?.toString(),
      },
    });
    setPocName(pocDetails.fullName);
    setPocNumber(pocDetails.mobile);
  };
  //get Beneficiary List//
  const getBeneficiaryList = async (benData: TicketProps) => {
    // console.log("check-2", benData);
    if (benData) {
      // console.log('benData?.subscriber?.id:', benData?.subscriber?.id)
      if (
        benData?.subscriber?.id !== "" &&
        benData?.ticketRaisedBy?.benId !== "" &&
        benData?.subscriber?.id !== undefined &&
        benData?.ticketRaisedBy?.benId !== undefined
      ) {
        try {
          const response = await getBeneficiaryData(benData.subscriber.id);
          setBeneficiaryList(response);
          return response;
        } catch (error) {}
      } else if (
        benData?.subscriber?.id === "" &&
        benData?.subscriber?.id === undefined &&
        benData?.ticketRaisedBy?.benId
      ) {
        try {
          const response = await getSingleBenDetails(
            benData.ticketRaisedBy.benId
          );
          setBeneficiaryList(response);
          return response;
        } catch (error: any) {
          alert(
            error?.response?.data?.message ||
              "Some Technical Issue in Getting BenData"
          );
        }
      } else {
        setBeneficiaryList([]);
      }
    }
  };
  ///get Patient Data//
  const getPatientDetails = async (patientData: TicketUpdateInterface) => {
    // console.log(patientData);
    if (patientData.patient) {
      // console.log('patient:', patientData.patient);
      createPatientDetails(
        patientData?.patient?.fullName,
        patientData?.patient?.mobile?.toString()
      );
      setPatientDetails(patientData.patient);
      // console.log("patientData?.patient?.address:", patientData?.patient?.address);
      const getPatientUpdatedAddress = patientData?.patient?.address?.length
        ? await getClosestAddress(patientData?.patient?.address)
        : [];
        console.log('getPatientUpdatedAddress:', getPatientUpdatedAddress);
      setAddressData(getPatientUpdatedAddress);
      setPreferredHospitalData([]); //once patient is changed preferredHospital will change
      setHospitalList([]); //once patient is changed HospitalList will change
      setPocName(data?.poc?.fullName || "");
      setPocNumber(data?.poc?.mobile || "");
    }
  };
  //find_Closest_Current_Location
  const getClosestAddress = async (patientAddress: Address[]) => {
    const patientUpdatedAddressList = await setAddressLatAndLon(patientAddress);
    if (data?.detectedLocation){    
    const getCurrentLocation = await findClosestAddress(
      data?.detectedLocation?.geoLocation?.coordinates[0],
      data?.detectedLocation?.geoLocation?.coordinates[1],
      patientUpdatedAddressList
    );
    let isCurrentLocation = false;

    // console.log('getCurrentLocation:---', getCurrentLocation);
    if (getCurrentLocation) {
      getCurrentLocation.currentLocation = true;
      // console.log('patientUpdatedAddressList:', patientUpdatedAddressList);
      // console.log("getCurrentLocation:", [...patientUpdatedAddressList, getCurrentLocation]);
      patientUpdatedAddressList.forEach((el: any) => {
        if (el?.addressLine1 === getCurrentLocation?.addressLine1 && el?.addressLine2 === getCurrentLocation?.addressLine2 && el?.city === getCurrentLocation?.city && el?.state === getCurrentLocation?.state && el?.pinCode === getCurrentLocation?.pinCode)  {
          el.currentLocation = true;
          el.location.latitude=getCurrentLocation?.location?.latitude
          el.location.longitude=getCurrentLocation?.location?.longitude
          isCurrentLocation = true;
        }
      })

      return isCurrentLocation? [...patientUpdatedAddressList]: [...patientUpdatedAddressList, getCurrentLocation];      
    } else {
      const currentLocationPayload = {
        addressLine1: data?.detectedLocation?.address,
        addressLine2: "",
        city: data?.detectedLocation?.city,
        country: data?.detectedLocation?.country,
        state: data?.detectedLocation?.state,
        pineCode: data?.detectedLocation?.pinCode,
        location: {
          latitude: data?.detectedLocation?.geoLocation?.coordinates[0],
          longitude: data?.detectedLocation?.geoLocation?.coordinates[1],
        },
        currentLocation: true,
      };
      // console.log('currentLocationPayload:', data?.detectedLocation,currentLocationPayload);
      return [...patientUpdatedAddressList, currentLocationPayload];
    }
  }else{
    return patientUpdatedAddressList;
  }
  };
  //set_address_lat & lon
  //if suppose the address not have lat and lon this function will find lat long using address
  //worst_case even this function also not find lat lon??
  const setAddressLatAndLon = async (patientAddress: Address[]) => {
    let newAddress: any = [];
    for(const el of patientAddress) {
      // console.log("------",el.location,el )
      if (el?.location?.latitude!==undefined && el?.location?.longitude!==undefined) {
        // console.log("####---object--location",el.location,el )
        newAddress.push(el);
        } else if(Array.isArray(el.location) &&el?.location[0]?.latitude!==undefined && el?.location[0]?.longitude!==undefined ){        
          // console.log("####---array--location",el.location,el )
          const updatedAddress = {
          ...el,
          location: {
            latitude: Number(el?.location[0]?.latitude),
            longitude: Number(el?.location[0]?.longitude),
          },
        };
        // console.log('updatedAddress:--------------', updatedAddress)
        newAddress.push(updatedAddress);
      }
      else {
        // console.log('el:no location', el);
        if(el.addressLine1 && el.addressLine2 && el.city && el.state && el.pinCode) {        
        
        const response = await getGeoCode(el);
        // console.log('response:', response);
        if (response) {
          const updatedAddress = {
            ...el,
            location: {
              latitude: Number(response?.lat),
              longitude: Number(response?.lng),
            },
          };
          // console.log('updatedAddress:', updatedAddress);
          newAddress.push(updatedAddress);
          // console.log('newAddress:--2', newAddress);
        }
      }else{
        const updatedAddress = {
          ...el,
          location: {
            latitude: 12.0000,
            longitude:77.0000,
          },
        };
        newAddress.push(updatedAddress);
      }
      }
    }

    // console.log('newAddress:', newAddress);
    return newAddress;
  };
  //check ambulance and doctor consolation is Req
  const handleCheckAmbulanceDoctorReq = (val: {
    ambulanceReq: string;
    doctorReq: string;
  }) => {
    if (val.ambulanceReq === "No" && val.doctorReq === "No") {
      setCheckAmbulanceDoctorReq(true);
    } else {
      setCheckAmbulanceDoctorReq(false);
    }
  };
  //address Lot and Lon Not Found find lat lon
  const getGeoCode = async (address: Address) => {
    // let addressText = createAddressPayload(address);
    // console.log('addressText:', addressText)
    try {
      const response = await geoCode(address);
      // console.log('response:', response);
      if (response) {
        return response;
      }
    } catch (e: any) {
      alert(
        e?.response?.data?.message || "Some Technical Issue Finding Lat and Lon"
      );
    }
  };
  //setPatientAddress
  const getPatientAddress = async (selectedAddress: Address) => {
    console.log('selectedAddress:@@@@@@@@@@@', selectedAddress)
    if (
      selectedAddress?.location.latitude &&
      selectedAddress?.location.longitude
    ) {
      createPatientPayload(selectedAddress);
    } else {
      const response = await getGeoCode(selectedAddress);
      if (response) {
        const data = {
          ...selectedAddress,
          geoLocation: {
            type: "Point",
            coordinates: [Number(response?.lat), Number(response?.lng)],
          },
        };
        // console.log('data:', data)
        createPatientPayload(data);
      }
      // alert("Address lat lon not valid"); //then using that address i need to find lat lon (reverseGeoCode)
    }
  };
  //buildPatientPayload
  const createPatientPayload = async (selectedAddress: Address) => {
    // console.log('selectedAddress:', selectedAddress)
    let addressText = createAddressPayload(selectedAddress);
    setTicketData({
      ...ticketData,
      patientLocation: {
        address: addressText,
        landmark: selectedAddress.landmark || "",
        isLiftAvailable: selectedAddress.isLiftAvailable || false,
        isReachableByAmbulance: selectedAddress.isReachableByAmbulance || false,
        label: selectedAddress.label || "",
        geoLocation: {
          type: "Point",
          coordinates: [
            selectedAddress?.location.latitude,
            selectedAddress?.location.longitude,
          ],
        },
      },
    });

    ///here i am creating payload
    const locationPayload = {
      latitude: Number(selectedAddress?.location.latitude),
      longitude: Number(selectedAddress?.location.longitude),
      radius: 5000,
    };
    // console.log('locationPayload:', locationPayload);
    // console.log('locationPayload:', locationPayload)
    await getHospital(locationPayload);
  };
  //build addressText for geoCode and patientAddress
  const createAddressPayload = (selectedAddress: Address) => {
    let addressText = `${selectedAddress.addressLine2 || ""}, ${
      selectedAddress.addressLine1
    }, ${selectedAddress.city}, ${selectedAddress.state}, ${
      selectedAddress.pinCode
    }`;
    return addressText;
  };
  //get PreferredHospital///
  const getPreferredHospital = (address: Address) => {
    // console.log('address.preferredHospital:', address.preferredHospital)
    setPreferredHospitalData(address?.preferredHospital || []);
  };

  //get Near Hospital//
  const getHospital = async (addressPayload: {
    latitude: any;
    longitude: any;
    radius: number;
  }) => {
    // console.log('addressPayload:', addressPayload)
    setHospitalList([]);
    ///need to call hospital api//
    try {
      const response = await getNearbyHospitals(addressPayload);
      // const response = await getAllHospital();
      // console.log('response:', response)
      if (response) {
        setHospitalList(response.data);
      }
    } catch (e: any) {
      alert(
        e?.response?.data?.message ||
          "Some Technical Issue in finding near by Hospital"
      );
    }
  };
  // console.log("@@@@@@@@@@@@@@",hospitalLists);
  const getTicketData = async () => {
    if (params.id) {
      try {
        const response = await getTicket(params.id, navigate);
        setData(response?.data);
        // console.log('response:in Update', response)
        const benList=await getBeneficiaryList(response?.data);
        // console.log('benList: in update', benList)
        if(response?.data?.patient?.id){
          getInsuranceDetails({patientId:response?.data.patient?.id,benList:benList})
        }
        
      } catch (e: any) {
        if (e?.response?.data?.message === "Unauthorized , Please login first") {
          alert(
            e?.response?.data?.message ||
              "Some Technical Issue in Getting Tickets"
          );
          // localStorage.setItem("isLogin", "false");
          return navigate("/login");
        } else {
          return alert(
            e?.response?.data?.message ||
              "Some Technical Issue in Getting Tickets"
          );
        }
      }
    }
  };
  const ticketUpdate = async () => {
    //  setRender(true)
    await getTicketData();
  };
 

  //getHospital

  const getHospitalContacts = (contact: string) => {
    setHospitalContact(contact);
  };
  //when ever agent make call to patient are em from call section that time need to update the call logs
  const updateCallLogs = () => {
    getCallLogs(params.id);
  };

  //getCallLogsByTicketId
  const getCallLogs = async (ticketId: any) => {
    try {
      const response = await getCallLogsByTicketId(ticketId);
      // console.log('response--2:', response);
      alreadyCalledList(response);
    } catch (e: any) {
      console.log("check",e);
      alert(
        e?.response?.data?.message ||
          "Some Technical Issue in getting Call Logs"
      );
    }
  };
  //separate callerId
  const alreadyCalledList = (callLog: any) => {
    // console.log('callLog:#############', callLog)
    const callerList: any = [];
    callLog?.forEach((el: any) => {
      console.log("el:", el);
      callerList.push({
        callerId: el.callerId,
        duration: el.callDuration,
        startTime: el.startTime,
        endTime: el.endTime,
        timeToAnswer: el.timeToAnswer,
        callStatus: el.status,
        hangupBy: el.hangupBy,
        agent: el.agentId,
        agentStatus: el.agentStatus,
      });
    });
    // console.log('callerList:', callerList)
    setCallLogList(callerList);
  };
  //updateTicket
  const getLatestTicketData = async () => {
    if (params.id) {
      try {
        const response = await getTicket(params.id, navigate);
        // console.log('response:', response)
        setData(response?.data);
      } catch (e: any) {
        alert(
          e?.response?.data?.message ||
            "Some Technical Issue in getting Call Logs"
        );
      }
    }
  };
  //render
  // const renderParentComponent=()=>{
  //   console.log("data",data)
  // }
  const companyEmContact = (ticketData: any) => {
    if (ticketData?.campusContacts) {
      const companyEmContacts = ticketData.campusContacts.map((el: any) => {
        return el.mobile && el.mobile;
      });
      // console.log('companyEmContacts:', companyEmContacts)
      setCompanyEmContacts(companyEmContacts);
    }
  };
  //  //getAllOffcies
  const getAvailableCompany = async () => {
    try {
      const res = await getAllCompany();
      const getCompanyNames = getOnlyCompanyName(res);
      setCompanyNames(getCompanyNames);
    } catch (e: any) {
      alert(
        e?.response?.data?.message || "Some Technical Issue in getting Company"
      );
    }
  };
  //helper//
  const getOnlyCompanyName = (companyData: any[]) => {
    return companyData.map((el: any) => ({
      label: el.companyName,
      value: el._id,
    }));
  };

  //updatePoc Data//
  const updatePocData = async (payload: {
    fullName: string;
    mobile: string;
  }) => {
    if (params.id) {
      try {
        const response = await updateTicket(params.id, payload);
        console.log("response:", response);
        setPocName(response?.data?.poc?.fullName);
        setPocNumber(response?.data?.poc?.mobile);
        setData(response?.data);
        alert("Poc Details Updated successfully");
      } catch (e: any) {
        alert(
          e?.response?.data?.message ||
            "Some Technical Issue in getting Company"
        );
      }
    }
  };
  //poc modal
  const handlePocModal = () => {
    setShowPocModal(!showPocModal);
  };

  useEffect(() => {
    let res: any;
    check();
    async function check() {
      // let isLogin = localStorage.getItem("isLogin");
      // if (isLogin === "false") {
      //   return navigate("/login");
      // }
      if (params.id) {
        // alert("check");
        try {
          const response = await getTicket(params.id, navigate);
          console.log("response:NNNNNNNNNNNNNNNNNNNNN", response);
          setData(response?.data);
          setAgent(response?.data?.agent?.fullName);
          // console.log("ticketId", params.id);
          await getCallLogs(params.id);
          const benList=await getBeneficiaryList(response?.data);
          console.log('benList:', benList);
          await getAvailableCompany();
          ////getting pocoTeamDetails////
          const pocoTeamData = await getInternalPocoTeamDetails();
          setPocoTeamData(pocoTeamData);
          companyEmContact(response?.data);
          res = response?.data;
          console.log('res:', res);
          if(res?.patient?.id){
            getInsuranceDetails({patientId:res?.patient?.id,benList:benList})

          }
          
          setTicketData({
            currentMedicalStatusResponse: {
              isItEmergency: "Yes",
              isAmbulanceRequired: "Yes",
              isDoctorConsultationRequired: "Yes",
              isPatientConscious: "Yes",
              currentCondition: [""],
              otherDescription: "",
            },
          });
        } catch (e: any) {
          alert(
            e?.response?.data?.message ||
              "Some Technical Issue in getting Call Logs"
          );
        }
      }
    }
    setPocDetails();
    ///it need to call in popup once Agent is selected need to send the name and it could be updated
    //eslint-disable-next-line
  }, [params]);
  // console.log('datasdsd:', ticketData)
  //
  ///get insurance Details for selected patient//
  const getInsuranceDetails =  ({patientId,benList}:any) => {
    const patientInsuranceData=benList?.filter((benData:any,index:number)=>benData._id===patientId);
    // console.log('patientInsuranceData:', patientInsuranceData);    
    setInsuranceData(patientInsuranceData[0].insurancePolicies||[]);
  }
  ///this useeEffect for first time rendering it will automatically update Agent details by using Cookie
  useEffect(()=>{
    (async () => {
      if (data&&params.id &&data?.ticketStatus !== "Completed" &&
      data?.ticketStatus !== "Cancelled" ) {
        try {
          const res = await updateTicket(params.id, {}); // Use await to wait for the updateTicket function to resolve
          // Handle the response
          // console.log('res:fromIffe', res);
          agentName(res?.data?.agent?.fullName);
        } catch (error) {
          // Handle errors
          console.error(error);
        }
      }
    })();
    //eslint-disable-next-line
  },[])
  return (
    <>
      <SideBar />
      <Box className={style.wrapper}>
        <Box className={style.flex}>
          <Box className={style.calls_wrapper}>
            {/* /leftSection */}
            {data ? (
              <CallSection
                ticketData={ticketData} //ticket data
                agentData={data}
                hospitalContact={hospitalContact}
                callLogsList={callLogsList}
                updateCallLogs={updateCallLogs}
                ticketUpdate={ticketUpdate}
              />
            ) : null}
          </Box>
          <Box className={style.forms_wrapper}>
            {/* //RightSection */}
            {data ? (
              <PersonalDetails
                handlePocModal={handlePocModal}
                ticketData={data}
                pocName={pocName}
                pocNumber={pocNumber}
                patientName={patientName}
                patientNumber={patientNumber} // benData={benData}
              />
            ) : null}
            {data ? (
              <BeneficiaryDetails
                agent={agent}
                data={data}
                ticketData={ticketData}
                setTicketData={setTicketData}
              />
            ) : null}
            {data ? (
              <PatientDetails
                // setInsuranceData={setInsuranceData}
                companyNames={companyNames}
                data={data}
                ticketData={ticketData}
                setTicketData={setTicketData}
                beneficiaryList={beneficiaryList}
                getPatientDetails={getPatientDetails} //it contain the selected patientDetails
              />
            ) : null}
            {data ? (
              <MedicalDetails
                data={data}
                ticketData={ticketData}
                setTicketData={setTicketData}
                handleCheckAmbulanceDoctorReq={handleCheckAmbulanceDoctorReq}
              />
            ) : null}
            {data && patientDetails && data.ticketStatus === "New" ? (
              <PickupLocation
                getPreferredHospital={getPreferredHospital}
                getPatientAddress={getPatientAddress}
                setAddressData={setAddressData}
                addressData={addressData}
                ticketData={ticketData}
                patientDetails={patientDetails}
              />
            ) : null}

            {data && patientDetails && data.ticketStatus === "New" ? (
              <PreferredHospital
                preferredHospitalData={preferredHospitalData}
              />
            ) : null}
            {data && patientDetails && data.ticketStatus === "New" ? (
              <NearByHospital
              data={data}
                preferredHospitalData={preferredHospitalData}
                ticketData={ticketData}
                hospitalLists={hospitalLists}
                getHospital={getHospital}
                setTicketData={setTicketData}
                getHospitalContacts={getHospitalContacts}
              />
            ) : null}
            {data && data.ticketStatus === "New" ? (
              <PointOfContact
                ticketData={ticketData}
                data={data}
                setPocName={setPocName}
                setPocNumber={setPocNumber}
                checkAmbulanceDoctorReq={checkAmbulanceDoctorReq}
                createPocPayload={createPocPayload}
                pocName={pocName}
                pocNumber={pocNumber}
                ticketUpdate={ticketUpdate}
                companyEmContacts={companyEmContacts}
              />
            ) : null}
            {data && data.ticketStatus !== "Cancelled" && (
              <EmergencyManagement
                getLatestTicketData={getLatestTicketData}
                data={data}
                companyEmContacts={companyEmContacts}
              />
            )}
            {data &&
              data.ticketStatus !== "Completed" &&
              data.ticketStatus !== "Cancelled" && (
                <>
                  <AmbulanceMessage data={data} />
                  <AmbulanceTracking
                    data={data}
                    companyEmContacts={companyEmContacts}
                  />
                </>
              )}
            {data &&
              data.ticketStatus !== "New" &&
              data.ticketStatus !== "Cancelled" && (
                <InsuranceDetails insuranceData={insuranceData} />
              )}

            {/* {showModal && data ? (
              <CallerPopup
                pocoTeamData={pocoTeamData}
                ticketData={ticketData}
                setTicketData={setTicketData}
                getData={data}
                ticketId={params?.id}
                handleCloseModal={handleCloseModal}
                agentName={agentName}
              />
            ) : null} */}
            {data?.ticketStatus === "Cancelled" && (
              <Card border="1px solid red" mt="1rem" padding="1.5rem">
                <Text textAlign="center" color="red">
                  Ticket Cancelled
                </Text>
              </Card>
            )}
          </Box>
          {/* //poc edit modal */}
          {showPocModal && (
            <PocModalWrapper
              handleCloseModal={handlePocModal}
              updatePocData={updatePocData}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
