import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { addDays } from "date-fns";
import moment from "moment";
import { useState } from "react";
import { DateRangePicker } from "react-date-range";
interface DatePickerProps{
    handleControlModal:()=>void,
    handleFilterByDate:(from:string,to:string)=>void
}
export const DatePicker:React.FC<DatePickerProps> = ({handleControlModal,handleFilterByDate}) => {
  const { onClose } = useDisclosure();
  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const formateDate = (type: string) => {
    const date =
      type === "from" ? dateState[0].startDate : dateState[0].endDate;
    const formateDate = moment(date).format("DD-MMM-YYYY");
    return formateDate; // Format the date as required
  };
  const handleSelectedDate = (item: any) => {
    // console.log([item.selection]);
    setDateState([item.selection]);
  };
  const handleCloseModal=()=>{
    onClose()
    handleControlModal();
  }

  const filterByDate=()=>{
    const startDate = formateDate("from");
    const endDate = formateDate("to");
    console.log('dateState:', dateState,startDate,endDate
    );
    handleFilterByDate(startDate,endDate)
    handleControlModal();
  }
  return (
    <>
      <Modal isOpen={true} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent maxH="600px" maxW="950px">
          <ModalHeader>Select Date</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <DateRangePicker
              onChange={(item: any) => handleSelectedDate(item)}
              // showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={dateState}
              direction="horizontal"
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleCloseModal}>
              Close
            </Button>
            <Button onClick={filterByDate} variant="ghost">
              Apply
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
