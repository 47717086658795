import { adminUserLogout } from "../../../Http";

  export const adminUserLogoutAction = async () => {
    try {
      const res = await adminUserLogout();
      return res.data;
    } catch (e: any) {
      throw e;
    }
  };
  