import React, { useEffect, useState } from 'react'
import { Box, Skeleton } from '@chakra-ui/react'
import profileStyle from "./Profile.module.css"
import { useNavigate, useParams } from 'react-router-dom';
import ProfileBasicDetail from '../../GlobalComponents/BasicDetails/BasicDetails';
import { BenDataProps } from '../../Helper/BeneInterface';
import ProfileEmergencyContact from './Componenets/EmergencyContacts/EmergencyContacts';
import ProfileMedicalDetails from '../../GlobalComponents/Medical/Medical';
import ProfileInsurance from './Componenets/Insurance/Insurance';
import ProfilePreferredHospital from './Componenets/PrefferedHospital/PrefferedHospital';
import Address from './Componenets/Address/Address';
import { getSingleBenDetailsForProfile } from './ProfileAction/ProfileAction';
import { getMedicalDetails } from './ProfileAction/getMedicalDetails';
import { generateSaSToken } from '../../Helper/generateSasToken';


const ProfilePerk: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [benData, setBentData] = useState<BenDataProps | null>(null);

    useEffect(() => {
        const getBeneficiaryData = async () => {
            try{

                const data = await getSingleBenDetailsForProfile(id || "");
                const medical = await getMedicalDetails(id || "");
                generateSaSToken();
                setBentData((pre:any)=>{
                    return {...pre, ...data.data, ...medical[0]}
                })
            }catch(e:any){
                alert(e?.response?.data?.message || "Some Technical Issue getting Profile Perk");
            }
        };
        getBeneficiaryData();
    }, [navigate, id]);
    
    return (
        <>
            <Box className={profileStyle.profile_wrapper}>
                {/* <Box className={profileStyle.profile_sub}> */}

                    <Box className={profileStyle.profile_right}>
                        {benData ? (
                            <Box id="basicInfo" className={profileStyle.benAddressDetails}>
                                <ProfileBasicDetail data={benData} />
                            </Box>
                        ) : (
                            <Box className={profileStyle.benAddressDetails} height={"100%"}>
                                <Skeleton height={"550px"} borderRadius={"20px"} />
                            </Box>
                        )}
                        {benData ? (
                            <Box id="addressInfo" className={profileStyle.benAddressDetails}>
                                <Address data={benData} />
                            </Box>
                        ) : (
                            ""
                        )}

                        {benData ? (
                            <Box
                                id="emergencyInfo"
                                className={profileStyle.benAddressDetails}
                            >
                                <ProfileEmergencyContact data={benData} />
                            </Box>
                        ) : (
                            ""
                        )}

                        {benData ? (
                            <Box id="medicalInfo" className={profileStyle.benAddressDetails}>
                                <ProfileMedicalDetails data={benData} />
                            </Box>
                        ) : (
                            ""
                        )}

                        {benData ? (
                            <Box
                                id="insuranceInfo"
                                className={profileStyle.benAddressDetails}
                            >
                                <ProfileInsurance data={benData} />
                            </Box>
                        ) : (
                            ""
                        )}

                        {benData ? (
                            <Box id="hospitalInfo" className={profileStyle.benAddressDetails}>
                                <ProfilePreferredHospital data={benData} />
                            </Box>
                        ) : (
                            ""
                        )}
                    </Box>
                {/* </Box> */}
            </Box>
        </>
    )
}

export default ProfilePerk;
