import { HStack, IconButton, Select, Text } from "@chakra-ui/react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
interface PaginationProps{
    pageSize:number;
    pageSizeChange:(pageSize:number)=>void;
    pageNumber:number;
    pageChange:(pageNum:number)=>void;
    hasNextPage:boolean
    count:number
}
export const Pagination:React.FC<PaginationProps> = ({pageSizeChange,pageNumber,pageChange,pageSize,hasNextPage,count}) => {
    const handlePageSizeChange=(value:string)=>{
        pageSizeChange(Number(value))
    }
    const handleDataChange=(value:string)=>{
        pageChange(Number(value));
    }
  return (
    <>
      <HStack marginBottom={"10px"} justifyContent={"flex-end"}>
        <HStack>
          <Select
            value={pageSize}
            onChange={(e) => handlePageSizeChange(e.target.value)}
          >
            <option value="10">10 </option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Select>
        </HStack>
        <HStack>
          <IconButton
            colorScheme="blue"
            aria-label="Search database"
              isDisabled={Number(pageNumber) === 1 ? true : false}
              onClick={() => handleDataChange("decreasePage")}
            icon={<GrFormPrevious />}
          />
          <Text>
        
            {pageNumber + "/" + Math.ceil((count||10) / pageSize)}
          </Text>
          <IconButton
            colorScheme="blue"
            aria-label="Search database"
              isDisabled={hasNextPage ? false : true}
              onClick={() => handleDataChange("increasePage")}
            icon={<GrFormNext />}
          />
        </HStack>
      </HStack>
    </>
  );
};
