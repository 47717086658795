import React from "react";
import style from "./customButton.module.css";
interface CustomButtonProps {
  handleClick: any;
  label: string;
  additionalData?: any;
  customStyle: any;
  disabled?:boolean;
  loading?:boolean
}
export const CustomButton: React.FC<CustomButtonProps> = ({
  handleClick,
  label,
  additionalData,
  customStyle,
  disabled,
  loading
}) => {
  const handleOnClick = () => {
    // console.log('additionalData:', additionalData);
    handleClick(additionalData);
  };
  return (
    <div>
      <button
        className={`${style.button} ${customStyle}`}
        onClick={handleOnClick}
        disabled={disabled}      
      >
        {loading?<div> loading..</div>:<>{label}</>}
        
      </button>
    </div>
  );
};
