import { Box, Text } from "@chakra-ui/react";
import insuranceStyle from "./InsuranceDetails.module.css";
import { CardWrapper } from "../../../../GlobalComponents/Card/CardWrapper";
interface InsuranceProps {
  insuranceData: any;
}
export const InsuranceDetails: React.FC<InsuranceProps> = ({
  insuranceData,
}) => {
  // const getSasToken=localStorage.getItem("sasToken");
  // console.log("insuranceData:", insuranceData);
  return (
    <Box className={insuranceStyle.wrapper}>
      <Box className={insuranceStyle.container}>
        <Box>
          <Text className={insuranceStyle.subTitle}>
            Insurance Details
          </Text>
        </Box>
      </Box>
      <Box className={insuranceStyle.address_grid}>
        {insuranceData.length ? (
          insuranceData.map((insuranceData: any, number: any) => {
            return (
              <Box className={insuranceStyle.card_wrapper}>
                <CardWrapper cardData={insuranceData} />
              </Box>
            );
          })
        ) : (
          <Box className={insuranceStyle.card_wrapper}>
            <Text>No data found</Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};
