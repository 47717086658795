import { getPocoTeamData } from "../../../../../Http";


export const getInternalPocoTeamDetails=async()=>{
    try{
        const response=await getPocoTeamData();
        return response?.data?.configurations;        
    }catch(e){
        throw e;
    }
}