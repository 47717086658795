import { Alert, Box, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import style from "./BeneficiaryDetails.module.css"
import { TicketProps, TicketUpdateInterface } from '../../../../Helper/TicketInterface';
const callerContent = [
    {
        ans: "Yes",
        content:
            "Please don't panic, we are here to help. We can arrange a doctor's consultation and book an ambulance. But to do that, we would need some details. Ok?",
    },
    {
        ans: "No",
        content: "Is there anything I could assist you with?",
    },
    {
        ans: "Maybe",
        content:
            "We will assume it's an emergency, capture necessary details, and arrange for a doctor's consultation. Post that, you can decide whether you need an ambulance or not. ok?",
    },
];
interface BenDataProps {
    data: TicketProps,
    ticketData: TicketUpdateInterface,
    setTicketData: any,
    agent: string
}
const BeneficiaryDetails: React.FC<BenDataProps> = ({ data, ticketData, setTicketData, agent }) => {
    const [checked, setIsChecked] = useState("Yes");
    const [ans, setAns] = useState("");

    useEffect(() => {
        if (checked) {
            const filteredAnswer = callerContent.filter((el) => el.ans === checked);
            setAns(filteredAnswer[0].content);
            setTicketData({
                ...ticketData,
                currentMedicalStatusResponse: {

                    ...ticketData.currentMedicalStatusResponse,
                    isItEmergency: checked,
                }
            });
        }
        // eslint-disable-next-line
    }, [checked]);
    return (
        <>
            <Box className={style.wrapper}>
                <Box className={style.container}>
                    {/* //Beneficiaries-details */}
                    {/* step:1 */}
                    {data?.ticketStatus !== "New"? (
                        <Box
                            display={"grid"}
                            gridTemplateColumns={"repeat(4,1fr)"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            gap={".5rem"}
                        >
                            <Stack
                                className={style.card}
                                direction={"row"}
                                align={"center"}
                                justify={"flex-start"}
                            >
                                <Box className={style.subTitle_top_Card}>Is Emergency:</Box>
                                <span color="black" style={{ fontWeight: "600" }}>
                                    {data?.currentMedicalStatusResponse?.isItEmergency}
                                </span>{" "}
                            </Stack>
                            <Stack
                                className={style.card}
                                direction={"row"}
                                align={"center"}
                                justify={"flex-start"}
                                gap={".5rem"}
                            >
                                <Box className={style.subTitle_top_Card}> Doctor Consult</Box>
                                <span color="black" style={{ fontWeight: "600" }}>
                                    {data?.currentMedicalStatusResponse?.isDoctorConsultationRequired}
                                </span>{" "}
                            </Stack>
                            <Stack
                                className={style.card}
                                direction={"row"}
                                align={"center"}
                                justify={"flex-start"}
                            >
                                <Box className={style.subTitle_top_Card}>
                                    Patient Conscious :
                                </Box>
                                <span color="black" style={{ fontWeight: "600" }}>
                                    {data?.currentMedicalStatusResponse?.isPatientConscious || "No"}
                                </span>{" "}
                            </Stack>
                            <Stack
                                className={style.card}
                                direction={"row"}
                                align={"center"}
                                justify={"flex-start"}
                            >
                                <Box className={style.subTitle_top_Card}>Ambulance:</Box>
                                <span color="black" style={{ fontWeight: "600" }}>
                                    {data?.currentMedicalStatusResponse?.isAmbulanceRequired}
                                </span>{" "}
                            </Stack>
                        </Box>
                    ) : (
                        <Box>
                            <Stack
                                className={style.card}
                                direction={"column"}
                                justify={"flex-start"}
                            >
                                <Box>
                                    <span className={style.subTitle}>Step : Emergency check</span>
                                </Box>

                                <Box>
                                    <Stack direction={"row"} spacing={".75rem"} align={"center"}>
                                        <Alert
                                            className={style.prompt_style}
                                            status="success"
                                            variant="left-accent"
                                        >
                                            Hi, this is {agent||data?.agent?.fullName} from{" "}
                                            PocoCare. Is there a medical emergency?
                                           
                                        </Alert>
                                    </Stack>
                                    <Stack
                                        mt={
                                            data?.ticketStatus !== "New" ? "0.3rem" : "0.85rem"
                                        }
                                        direction={"row"}
                                        spacing={"1rem"}
                                        align={"center"}
                                    >
                                        <Text className={style.Ben_Style}>Beneficiary :</Text>

                                        <RadioGroup
                                            value={checked}
                                            onChange={setIsChecked}
                                            display={"flex"}
                                            gap={"1rem"}
                                        >
                                            <Stack
                                                direction="row"
                                                spacing={".575rem"}
                                                align={"center"}
                                            >
                                                <Radio value="Yes" size="lg" />
                                                <Text>Yes</Text>
                                            </Stack>
                                            <Stack
                                                direction="row"
                                                spacing={".575rem"}
                                                align={"center"}
                                            >
                                                <Radio value="Maybe" size="lg" />
                                                <Text>Maybe</Text>
                                            </Stack>
                                            <Stack
                                                direction="row"
                                                spacing={".575rem"}
                                                align={"center"}
                                            >
                                                <Radio value="No" size="lg" />
                                                <Text>No</Text>
                                            </Stack>
                                        </RadioGroup>
                                    </Stack>
                                    {checked && data?.ticketStatus === "New" ? (
                                        <Alert
                                            mt={"1rem"}
                                            className={style.prompt_style_ans}
                                            status="info"
                                            variant="left-accent"
                                        >
                                            {ans}
                                        </Alert>
                                    ) : null}
                                </Box>
                            </Stack>
                        </Box>
                    )}

                </Box>
            </Box>

        </>
    )
}

export default BeneficiaryDetails
