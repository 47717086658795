import { Box, Button, Center, HStack, Text } from "@chakra-ui/react";
import { CustomTabel } from "../DashBoard/Component/CustomTabel/CustomTabel";
import { useEffect, useState } from "react";
import SideBar from "../../GlobalComponents/Sidebar/SideBar";
import {
  buildQueryForOutBoundCall,
  getListOfOutBoundCall,
} from "./Action/getListOutboundCallList";
// import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { Pagination } from "../../GlobalComponents/Pagination/Pagination";
// import { addDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "../../GlobalComponents/DataPicker/DatePicker";
const tableHeaders = ["S.No", "CallerNumber", "Date", "Status"];
export const OutBoundCall = () => {
  ////////need to colect data from ls who is logged in to show only that data in the tabel////////
  // const getAgentName = localStorage.getItem("agentData");
  // const parsedData = getAgentName ? JSON.parse(getAgentName) : undefined;
  const [ountBoundCalllDet, setOutboundCallDet] = useState<any>([]);
  const [isModelOpen,setIsModelOpen]=useState(false)
  const [defultValues, setDefultValues] = useState<{
    pageSize: number;
    pageNumber: number;
    to: string;
    from: string;
  }>({
    pageSize: 10,
    pageNumber: 1,
    to: "",
    from: "",
   
  });
  const navigate = useNavigate();
 
const handlClearFilter=()=>{
  setDefultValues((prev)=>({...prev, pageSize: 10,
    pageNumber: 1,
    to: "",
    from: "",

  }))
}
  const getOutboundList = async (payload: any) => {
    // console.log('loggedInAgentName:', loggedInAgentName);
    const urlForOutBoundCall = buildQueryForOutBoundCall(payload);
    try {
      const res = await getListOfOutBoundCall(urlForOutBoundCall);
      if (res?.data) {
        setOutboundCallDet(res);
      }
    } catch (e: any) {
      alert(
        e?.response?.message ||
          "some technical issue in getting outbound call details"
      );
    }
  };
  const pageSizeChange = async (value: number) => {
    // console.log('value:', value);
    setDefultValues((prevValues) => ({ ...prevValues, pageSize: value }));
  };
  const pageChange = (value: number) => {
    // console.log('value:', value);
    setDefultValues((prevValues) => ({ ...prevValues, pageNumber: value }));
  };

  const storeCallHistoreData = (data: any, mobileNum: string) => {
    console.log("mobileNum:", mobileNum);
    //herer need to wrap with routing wraaper it should check in the
    navigate(`/outboundCallDetail/${mobileNum}`);
  };

  const handleControlModal=()=>{
    setIsModelOpen(!isModelOpen);
  }

  const handleFilterByDate=(from:string,to:string)=>{
    setDefultValues((prevValues) => ({ ...prevValues, from: from,to:to }));
  }

  useEffect(() => {
    //call the outboundApi with agentName;
    getOutboundList(defultValues);
    //eslint-disable-next-line
  }, [defultValues]);
  // console.log('defultValues:', defultValues);
  return (
    <>
      <SideBar />
      <Box w="100%">
        <HStack ml={"1rem"} mt={"1rem"}>
          <Button onClick={()=>handleControlModal()} colorScheme="blue">Filter by date</Button>
          <Button onClick={()=>handlClearFilter()} colorScheme="blue">Clear FIlter</Button>
        </HStack>
        <Center>
          <Text>
            OutBound Call History           
          </Text>
        </Center>
        <Box>
          <CustomTabel
            storeCallHistoreData={storeCallHistoreData}
            tableHeaders={tableHeaders}
            ountBoundCalllDet={
              ountBoundCalllDet?.data ? ountBoundCalllDet?.data : []
            }
          />

          <Box mt={"1rem"}>
            <Pagination
              pageNumber={defultValues?.pageNumber}
              pageSize={defultValues?.pageSize}
              pageSizeChange={pageSizeChange}
              pageChange={pageChange}
              hasNextPage={ountBoundCalllDet.hasNextPage}
              count={ountBoundCalllDet?.count ? ountBoundCalllDet?.count : 10}
            />
          </Box>
        </Box>
      </Box>
      {isModelOpen&&<DatePicker handleFilterByDate={handleFilterByDate} handleControlModal={handleControlModal}/>}
    </>
  );
};
