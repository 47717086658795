import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Input,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import {
  agentPwdUpdateAction,
  forgotPwdVerifyEmailAction,
} from "./Action/ForgotPwdActions";
import { useNavigate } from "react-router-dom";
import VerifySecretAndUpdatePwd from "./Components/VerifySecretAndUpdatePwd";

const ForgotPwd = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [disable, setDisable] = useState<boolean>(false);
  const [pwdChange, setPwdAndSecKey] = useState({
    secretKey: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [status, setStatus] = useState({
    verifyEmail: true,
    verifySecret: false,
    setNewPwd: false,
  });
  
  const navigte = useNavigate();

  const handleVerifyEmail = async () => {
    try {
      if (email.trim().length === 0) {
        return alert("Please enter valid email.");
      }
      setLoading(true);
      let res = await forgotPwdVerifyEmailAction({ email: email });
      localStorage.setItem("userEmail", JSON.stringify(email));
      setDisable(true);
      alert(res?.message + " change the Password using that key.");
      setLoading(false);
      setStatus({ verifyEmail: false, verifySecret: true, setNewPwd: false });
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.status === 400) {
        alert(error?.response?.data?.message[0]);
      } else if (error?.response?.status === 404) {
        alert(error?.response?.data?.message);
      } else {
        alert("Internal Server Error");
      }
    }
  };

  const handleChange = (e: any) => {
    let value = e.target.value;
    if (value.length > 13 && value.slice(-13) === "@pococare.com") {
      setDisable(false);
    } else {
      setDisable(true);
    }
    setEmail(e.target.value);
  };

  const handlePwdChange = (e: any) => {
    setPwdAndSecKey((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmitPwd = async () => {
    try {
      let parsedEmail;
      const userEmail = localStorage.getItem("userEmail");
      if (userEmail) {
        parsedEmail = JSON.parse(userEmail);
      }
      if (
        pwdChange.confirmPassword.trim().length > 0 &&
        pwdChange.confirmPassword.trim() !== pwdChange.newPassword.trim()
      ) {
        return alert("New password and confirm password not same.");
      }
      if (pwdChange.confirmPassword.trim().length < 8) {
        return alert("Please enter valid secret.");
      }
      setLoading(true);
      const pwdPayload = {
        email: parsedEmail,
        password: pwdChange.confirmPassword,
        secretKey: pwdChange.secretKey,
      };
      console.log(pwdPayload, "****pwdPayload****");
      const res = await agentPwdUpdateAction(pwdPayload);
      alert(res?.message + " Please relogin with new credentials.");
      localStorage.clear();
      setLoading(false);
      navigte("/login");
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.status === 400) {
        return alert(error?.response?.data?.message[0]);
      } else if (error?.response?.status === 404) {
        return alert(error?.response?.data?.message);
      } else if (error.response.data.statusCode === 409) {
        return alert(error?.response?.data?.message);
      } else if (error.response.data.statusCode === 401) {
        return alert(error?.response?.data?.message);
      } else {
        return alert("Internal Server Error");
      }
    }
  };

  return (
    <Box
      width={{ md: "70%", base: "100%" }}
      alignItems={"center"}
      margin={"auto"}
    >
      {status.verifyEmail && (
        <Box>
          <Flex
            boxShadow="2xl"
            padding={"20px"}
            width={{ base: "95%", md: "80%" }}
            flexDir={{ base: "column", md: "row" }}
            alignItems={"center"}
            margin={"auto"}
            justifyContent={"space-between"}
          >
            <Box width={"80%"}>
              {" "}
              <Image
                width={"80%"}
                margin="15px"
                src="https://developmentbucket.blob.core.windows.net/logos/undraw_forgot_password_re_hxwm.svg"
              />
            </Box>
            <Box width={{ base: "100%", md: "50%" }}>
              <Box ml={"4"}>
                <Heading
                  width={{ base: "40%", md: "80%" }}
                  margin="10px"
                  minW={"70%"}
                >
                  Forgot Your Password.
                </Heading>
                <Box margin="10px">
                  <Text>Verify Email & Change</Text>
                  <Input
                    mt={"8px"}
                    mb={"16px"}
                    width="100%"
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e) => handleChange(e)}
                    htmlSize={4}
                  />
                  <br />
                  <Button
                    onClick={handleVerifyEmail}
                    bgColor={"#d53f8c"}
                    color={"white"}
                    isLoading={loading}
                    isDisabled={disable}
                    loadingText={"Verifying user"}
                    width="100%"
                  >
                    Verify Email
                  </Button>
                </Box>
              </Box>
            </Box>
          </Flex>
        </Box>
      )}
      {status.verifySecret && (
        <VerifySecretAndUpdatePwd
          handlePwdChange={handlePwdChange}
          handleSubmitPwd={handleSubmitPwd}
          pwdChange={pwdChange}
          loading={loading}
        />
      )}
    </Box>
  );
};

export default ForgotPwd;
