import { Box, Flex, Text } from "@chakra-ui/react";
import hospitalStyle from "./PreferredHospital.module.css";
import React from "react";
// import { PreferredHospitals } from "../../../Helper/BeneInterface";
interface PreferredHospitalProps {
  preferredHospitalData: any;
}
const PreferredHospital: React.FC<PreferredHospitalProps> = ({
  preferredHospitalData,
}) => {
  // console.log("preferredHospitalData:", preferredHospitalData);
  return (
    <Box className={hospitalStyle.hospital_wrapper}>
      <Box className={hospitalStyle.hospital_container}>
        <Box>
          <Text className={hospitalStyle.subTitle}>
            Step : Preferred Hospital
          </Text>
        </Box>

        {!!preferredHospitalData ? (
          <Box className={hospitalStyle.hospitals_details}>
            <Box>
              <Box className={hospitalStyle.left}>
                <Flex align={"center"} gap={"10px"}>
                  <Box>
                    <Text className={hospitalStyle.typeHospital}>
                      {`${preferredHospitalData[0]?.hospitalName || ""} ${
                        preferredHospitalData[0]?.hospitalAddress || ""
                      }` || ""}
                    </Text>
                  </Box>
                </Flex>
                <Box>
                  {/* <Text className={hospitalStyle.hospitalAddress}>
                  {showPreferredHospital?.speciality || ""}
                </Text> */}
                  {/* <Text className={hospitalStyle.hospitalAddress}>
                  {showPreferredHospital?.hospitalName || ""}
                </Text> */}
                  {/* <Text className={hospitalStyle.hospitalAddress}>
                  {showPreferredHospital?.hospitalAddress || ""}
                </Text> */}
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box>No Preferred Hospital </Box>
        )}
      </Box>
    </Box>
  );
};

export default PreferredHospital;
