import { getAllCallLogsByTicketId,  } from "../../../Http";

export const getCallLogsByTicketId = async (ticketId:any) => {
  try {
    const response = await getAllCallLogsByTicketId(ticketId);
    // console.log('response@@@@@@@@@@@@@@@@@@@@@:', response);
    return response.data.data;
  } catch (e: any) {
    // console.log("e",e)
   throw e;
  }
};


