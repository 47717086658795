import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Image,
  Input,
  Text,
} from "@chakra-ui/react";
import React, { ChangeEvent } from "react";

interface VerifySecretProps {
  handlePwdChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmitPwd: () => Promise<void>;
  loading: any;
  pwdChange: any;
}
const VerifySecretAndUpdatePwd: React.FC<VerifySecretProps> = ({
  handlePwdChange,
  handleSubmitPwd,
  loading,
  pwdChange,
}) => {
  return (
    <Box
      boxShadow="2xl"
      borderRadius={"10px"}
      h={"auto"}
      width={{ md: "40%", base: "95%" }}
      margin={"auto"}
      padding={9}
    >
      <Box mt={5} display={"flex"} justifyContent={"space-around"}>
        <Image
          w={"40%"}
          src="https://pococare.com/Images/logo.png"
          alt="Pococare Logo"
        />
      </Box>

      <Box minWidth={"70%"} margin={"auto"} mt={7} mb={10}>
        <FormControl>
          <FormLabel mt={4}>Enter secret key</FormLabel>
          <Input
            type="text"
            name="secretKey"
            required
            onChange={(e) => handlePwdChange(e)}
            value={pwdChange.secretKey || ""}
            placeholder="Enter secret key sent to your mail"
          />

          <FormLabel mt={4}>Enter New Password</FormLabel>
          <Input
            name="newPassword"
            onChange={(e) => handlePwdChange(e)}
            type="password"
            required
            value={pwdChange.newPassword || ""}
            placeholder="New Pass : Mypwd@79966"
          />
          <FormLabel mt={3}>Confirm Password</FormLabel>
          <Input
            name="confirmPassword"
            onChange={(e) => handlePwdChange(e)}
            required
            value={pwdChange.confirmPassword || ""}
            type="password"
            placeholder="Confirm Pass : Mypwd@79966"
          />
        </FormControl>
        <Box mt={2}>
          <Text color={"red"} fontSize="xs">
            Note: Password must contain 1 alphabet either uppercase or
            lowercase, 1 special character, 1 number, and length should be 8 or
            more. Example: Mypwd@79966
          </Text>
        </Box>
        <Box mt={6}>
          <Button
            onClick={handleSubmitPwd}
            color={"white"}
            bgColor={"#f53395"}
            width={"full"}
            isLoading={loading}
            loadingText={"Loading.."}
          >
            Change Password
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default VerifySecretAndUpdatePwd;
