import { Box, Button, Text, Textarea } from "@chakra-ui/react";
import { useState } from "react";
interface CallCardProps {
  callDetails: any;
  updateAgentNotes: (data: any) => void;
  subData:any
}
export const CallCard: React.FC<CallCardProps> = ({
  callDetails,
  updateAgentNotes,
  subData
}) => {
 
  const [inputValue, setInputValue] = useState(
    callDetails.agentNotes ? callDetails.agentNotes : ""
  );
  const updateDetails = () => {
    const payload = {
      agentNotes: inputValue,
      ucid: callDetails.ucid,
    };
    // console.log('payload:', payload);
    updateAgentNotes(payload);
  };
  const handleCatchValue = (e: any) => {
    console.log("dfdf", e.target.value);
    setInputValue(e.target.value);
  };
  return (
    <>
      <Box w={"90%"} boxShadow={"md"} borderRadius={"1rem"} padding={"1rem"}>
        <Text textAlign={"center"}>CallerType:{subData?"Beneficiary":"Unknown"}</Text>
        <Box display={"flex"} justifyContent={"space-between"}>
      
          <Box
            // border={"1px"}
            w={"35%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
          >
            <Text>
              StartTime:
              {new Date(callDetails.startTime).toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: true,
              })}
            </Text>
            <Text>
              EndTime:
              {new Date(callDetails.endTIme).toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: true,
              })}
              <Text>Hangup:{callDetails.hangupBy}</Text>
              <Text>Duration:{callDetails.duration}</Text>
            {  subData&&<Text>SubScriberName:{subData?.personalInfo?.fullName}</Text>}
            </Text>
          </Box>
          <Box
            // border={"1px"}
            w={"50%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box w={"70%"}>
              <Textarea
                value={inputValue}
                onChange={(e) => handleCatchValue(e)}
                mt={".8rem"}
                placeholder="update Call Status"
              />
            </Box>

            <Box w={"20%"}>
              <Button onClick={() => updateDetails()}>Update</Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
