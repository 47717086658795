import React, { useState } from "react";
import style from "./PateintDetails.module.css";
import {
  Alert,
  Box,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  TicketProps,
  TicketUpdateInterface,
} from "../../../../Helper/TicketInterface";
import { changeDateFormate } from "../../../../Helper/CalculateAge";
import Multiselect from "multiselect-react-dropdown";
import { getBeneficiaryDataByMobile } from "../../Action/getBenByMobile";
import { BenDataProps } from "../../../../Helper/BeneInterface";
import { getBenByCompany } from "./Action/getBenByCompanyName";
// import { companyContacts } from "./Action/getCompanyContact";
interface BenProps {
  data: TicketProps;
  ticketData: TicketUpdateInterface;
  setTicketData: any;
  beneficiaryList: any;
  getPatientDetails: (patientData: TicketUpdateInterface) => void;
  companyNames:any;
  // setInsuranceData:any;
  
}
const PatientDetails: React.FC<BenProps> = ({
  companyNames,
  data,
  ticketData,
  setTicketData,
  beneficiaryList,
  getPatientDetails,
  // setInsuranceData
}) => {
  const [benId, setBenId] = useState("");
  const [selectedPatients, setSelectedPatients] = useState<any>();
  const [findBeneficiary, setBeneficiary] = useState("mobileNumber");
  const [phone, setPhone] = useState("");
  const [benName, setBenName] = useState("");
  const [searchResults, setSearchResults] = useState<BenDataProps[]>([]);
  const [selectedBenByNumber, setSelectedBenByNumber] = useState<any>("");
  const [companyId,setCompanyId]=useState<string>("")
  const [findBen, setFindBen] = useState(""); 
  const [selectedOptions, setSelectedOptions] = useState([]);
  // console.log('beneficiaryList:', beneficiaryList);
  const handlePatientChange = (e: any) => {
    // console.log("check the patient is selected", selectedPatients?._id);
    setBenId(selectedPatients?._id);
    setFindBen("");
    // PatientChange();
    // console.log('selectedPatients:', selectedPatients)
    createPatientPayload(selectedPatients);
    // UpdateSearBenByNumber(selectedPatients?.id);
    //  updatePersonalDetails(selectedPatients?.id);
  };

  const handleSearchBen = async () => {
    if (phone.length > 10 || phone.length < 10) {
      alert("Please Enter valid Phone Number");
      setPhone("");
      setSearchResults([]);
    } else {
      try {
        const searchPhoneNumber = await getBeneficiaryDataByMobile(phone);
        // console.log('searchPhoneNumber:', searchPhoneNumber);
        setSearchResults(searchPhoneNumber);
      } catch (error: any) {
        alert(
          error?.response?.data?.message ||
            "Some Technical Issue Get Beneficiary"
        );
        setSearchResults([]);
        setPhone("");
      }
    }
  };
  const handleBenChange = (e: any) => {
    setFindBen(selectedBenByNumber._id);
    setBenId("");
    // console.log('selectedBenByNumber:', selectedBenByNumber);
    createPatientPayload(selectedBenByNumber);
  };

  const createEmContactPayload = async (emContact: any) => {
    const emList: any = [];
    // console.log('emContact:', emContact)
    emContact.map((el: any, i: number) => {
      return emList.push({
        fullName: el.fullName,
        mobile: el?.mobile?.number?.toString(),
        ...(el?.whatsappMobile?.number
          && { whatsappNumber: el.whatsappMobile.number.toString() }
          ),
      });
    });

    // if (data?.campusId) {
    //   const res = await companyContacts(data?.campusId);
    //   console.log(res)
    //   const transformedData = Array.isArray(res.officeContacts) ? res.officeContacts : [res.officeContacts];
    //   transformedData.map((el:any,i:number)=>{
    //     return emList.push({
    //       fullName:el?.name,
    //       mobile:el?.mobile?.number?.toString(),
    //       whatsappNumber:el?.whatsappMobile?.number?.toString
    //     })
        
    //   });
  
    // }
    return emList;
  };
  function capitalizeFirstLetter(string:string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const createPatientPayload = async(selectedPatients: any) => {
    // console.log('selectedPatients:', selectedPatients)
    const findPatientAge = changeDateFormate(
      selectedPatients?.personalInfo?.dateOfBirth
    );
    // console.log("selectedPatients:", selectedPatients);

    // console.log("check",selectedPatients?.personalInfo.gender)
    const latestTicketData = {
      ...ticketData,
      patient: {
        fullName: selectedPatients?.personalInfo?.fullName || "",
        id: selectedPatients?._id || "",
        mobile: selectedPatients?.personalInfo?.mobile?.number?.toString(),
        whatsappNumber:
          selectedPatients?.personalInfo?.whatsappMobile?.number?.toString(),
        email: selectedPatients?.personalInfo?.email || "",
        age: findPatientAge || "",
        languages: selectedPatients?.personalInfo?.languages || "",
        emergencyContacts: await createEmContactPayload(
          selectedPatients?.emergencyContacts || []
        ),
        address: selectedPatients?.address || "",
        ...(selectedPatients?.personalInfo?.gender
          ? { sex: capitalizeFirstLetter(selectedPatients?.personalInfo?.gender) }
          : {}),
      },
    };
    // console.log('latestTicketData:', latestTicketData)
    // setInsuranceData(selectedPatients?.insurancePolicies||[])
    setTicketData(latestTicketData);
    getPatientDetails(latestTicketData);
  };
  const handleSelect = (selectedList: any) => {
    // console.log('selectedList:', selectedList)
    setSelectedOptions(selectedList.label);
    setCompanyId(selectedList.value);
  };
  // console.log('companyId:', companyId);
  ///api is not ready/////
  const findBenByCompanyName = async () => {
    if (!benName && !companyId) {
      return alert("Please enter ben name or select company name");
    }
    try{
      const payload={
        benName:benName,
        companyId:companyId
      }

      const benList = await getBenByCompany(
        payload
      );
      if (benList.length > 0) {
        // console.log('benList:', benList);
        setSearchResults(benList);
        setSelectedOptions([])
        setBenName("")
        // setFilterCompany(compayNames);
      } 
    }catch(e:any){
      setSearchResults([]);
      setSelectedOptions([]);
    return alert(e?.response?.data?.message||"SOme Technical Issue In Finiding Ben By Company")
    }
    
  };

  // const findCompanyId=(companyName:any)=>{
  //   // console.log('companyName:', companyName);
  //   // console.log('el.split(":")[0]:', compayNames[0].split(":")[0]);
  //   const getSelectedCompanyId= filterCompany.filter((el:any)=>companyName[0]===el.split(":")[0]);
  //   // console.log('getSelectedCompanyId:', getSelectedCompanyId)
  //   const id = getSelectedCompanyId[0]?.split(':')[1];
  //   // console.log('id:', id)
  //   setCompanyId(id);
    
  // }
  // console.log('compayNames:', compayNames);

  return (
    <>
      <Box className={style.wrapper}>
        <Box className={style.container}>
          {data?.ticketStatus !== "New" ? null : (
            <Box>
              <Stack
                mt={"1rem"}
                className={style.card}
                direction={"column"}
                justify={"flex-start"}
              >
                <Box>
                  <Text className={style.subTitle}>Step : Select Patient</Text>
                </Box>

                <Box>
                  <Stack direction={"row"} spacing={".75rem"} align={"center"}>
                    <Alert
                      className={style.prompt_style}
                      status="success"
                      variant="left-accent"
                    >
                      What is the patient's name?
                    </Alert>
                  </Stack>

                  <Box>
                    <RadioGroup
                      value={benId}
                      onChange={(e) => handlePatientChange(e)}
                      className={style.name_grid}
                    >
                      {beneficiaryList.length > 0 ? (
                        beneficiaryList?.map((el: any, i: number) => {
                          return (
                            <React.Fragment key={i}>
                              <Stack
                                className={style.name_card}
                                direction="row"
                                justifyContent={"space-between"}
                                spacing={"5rem"}
                                align={"center"}
                              >
                                <Text>{el?.personalInfo.fullName}</Text>
                                <Radio
                                  onClick={() => setSelectedPatients(el)}
                                  value={el?._id}
                                  size="lg"
                                />
                              </Stack>
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <Text>Caller Is Not Poco Subscriber</Text>
                      )}
                    </RadioGroup>
                  </Box>
                </Box>
                <Box>
                  <Box>
                    <Text className={style.subTitle}>Find Beneficiaries </Text>
                  </Box>
                  <RadioGroup
                    value={findBeneficiary}
                    onChange={setBeneficiary}
                    display={"flex"}
                    gap={"1rem"}
                  >
                    <Stack direction="row" spacing={".575rem"} align={"center"}>
                      <Radio value="mobileNumber" size="lg" />
                      <Text>By MobileNumber</Text>
                    </Stack>
                    <Stack direction="row" spacing={".575rem"} align={"center"}>
                      <Radio value="CompanyName&BenName" size="lg" />
                      <Text>By CompanyName & BeneficiaryName</Text>
                    </Stack>
                  </RadioGroup>
                  {findBeneficiary === "mobileNumber" ? (
                    <Stack
                      mt={"1rem"}
                      direction={"row"}
                      align={"center"}
                      spacing={"1.1rem"}
                    >
                      <Box>
                        <InputGroup size="md">
                          <Input
                            value={phone}
                            onChange={(e) => {
                              const numericValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                              setPhone(numericValue);
                            }}
                            pr="4.5rem"
                            maxLength={10}
                            placeholder="Enter Mobile Number"
                          />
                          <InputRightElement width="4.5rem">
                            <Button
                              onClick={handleSearchBen}
                              h="1.75rem"
                              size="sm"
                            >
                              Search
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                      </Box>
                    </Stack>
                  ) : (
                    <Stack
                      mt={"1rem"}
                      direction={"row"}
                      align={"center"}
                      spacing={"1.1rem"}
                    >
                      <Box>
                        <Input
                          value={benName}
                          onChange={(e) => setBenName(e.target.value)}
                          pr="4.5rem"
                          placeholder="Enter Ben Name"
                        />
                      </Box>
                      <Box>
                        <Multiselect
                          // isObject={false}
                          onKeyPressFn={function noRefCheck() {}}
                          selectedValues={selectedOptions}
                          onRemove={(selectedList) =>
                            handleSelect(selectedList)
                          }
                          onSearch={function noRefCheck() {}}
                          onSelect={(selectedList) =>
                            handleSelect(selectedList)
                          }
                          
                          options={companyNames}
                          selectionLimit={1}
                          displayValue="label" 
                          placeholder="Select Companies"
                        />
                      </Box>
                      <Box>
                        <Button onClick={findBenByCompanyName} size="md">
                          Search
                        </Button>
                      </Box>
                    </Stack>
                  )}
                  <Box>
                    <RadioGroup
                      value={findBen}
                      onChange={(e) => handleBenChange(e)}
                      className={style.name_grid}
                    >
                      {searchResults.length
                        ? searchResults?.map((el: BenDataProps, i) => {
                            return (
                              <React.Fragment key={i}>
                                <Stack
                                  className={style.name_card}
                                  direction="row"
                                  justifyContent={"space-between"}
                                  spacing={"5rem"}
                                  align={"center"}
                                >
                                  <Text>{el.personalInfo.fullName}</Text>
                                  <Radio
                                    onClick={() => setSelectedBenByNumber(el)}
                                    value={el?._id}
                                    size="lg"
                                  />
                                </Stack>
                              </React.Fragment>
                            );
                          })
                        : null}
                    </RadioGroup>
                  </Box>
                </Box>
              </Stack>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default PatientDetails;
