import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import SideBar from "../../GlobalComponents/Sidebar/SideBar";
import { CallCard } from "../../GlobalComponents/CallCard/Card";
import { agentNotes } from "./Action/updateAgentNotes";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getBeneficiaryDataByMobile } from "../DashBoard/Action/getBenByMobile";

export const OutboundCallDetails: React.FC = () => {
  const { num } = useParams();
  const getData = localStorage.getItem("callHistory");
  const getParesedData = getData ? JSON.parse(getData) : [];
  const [callHistory, setCallHistory] = useState<any>();
  const [subData,setSubData]=useState<any>()
  const updateAgentNotes = async (data: {
    ucid: string;
    agentNotes: string;
  }) => {
    const updatedData = { ...data, _id: callHistory[0]._id };
    try {
      const res = await agentNotes(updatedData);
      if (res) {
        let data={data:[res]}
        localStorage.setItem("callHistory",JSON.stringify(data))
        alert("Updated SuccessFully");
      }
    } catch (e: any) {
      alert(e?.response?.message);
    }
  };
  const fetchDataFromLs = (num: string) => {
    // console.log("getParesedData:", getParesedData.data[0].callerId, num);
    const res = getParesedData?.data?.filter((el: any) => {
      return el.callerId === num.toString();
    });
    console.log("res:", res);
    setCallHistory(res);
  };
  const getBenData=async (num:string)=>{
    try{        
     const res=await getBeneficiaryDataByMobile(num);
      if(res){
       getSubscriber(res);
      }
    }catch(e:any){
        console.log("error",e);
        alert(
            e?.response?.data?.message ||
              "Some Technical Issue Get Beneficiary"
          );
    }
  }

  const getSubscriber=(benData:any)=>{
    console.log('benData:', benData);
    const findSubscriber=benData.length&&benData.filter((el:any,i:number)=>{
        return el?.personalInfo?.relationshipToSubscriber?.toUpperCase()==="SELF" 
    })
    //how we are going to avoid dublicate self in older data ///
    setSubData(findSubscriber?.length>0?findSubscriber[0]:findSubscriber);

  }
  useEffect(() => {
    if (num) {
      fetchDataFromLs(num);
      getBenData(num);
    }
    //eslint-disable-next-line
  }, [num]);
  return (
    <>
      <SideBar />
      <Box w={"90%"}>
        <Box mt={"1rem"}>
          <Text textAlign={"center"}>
            Call History For this-{num?num:"not found"}
          </Text>
        </Box>
        <Box mt={"2rem"} ml={"2rem"} w={"100%"} margin={"center"}>
          <SimpleGrid w={"100%"} columns={{ md: 1, base: 1 }} gap={"2rem"}>
            {!callHistory?.length ? (
              <>No Data Found</>
            ) : (
                callHistory?.map((el: any) => {
                    return el?.callDetails?.map((callDetail: any) => {
                      return (
                        <React.Fragment key={callDetail.ucid}>
                    
                          <CallCard
                            updateAgentNotes={updateAgentNotes}
                            callDetails={callDetail}
                            subData={subData}
                          />
                        </React.Fragment>
                      );
                    });
                })
            )}
          </SimpleGrid>
        </Box>
      </Box>
    </>
  );
};
