import { getAllTicket } from "../../../Http";

export const getTicketData = async (payload: string, navigate: any) => {
    try {
        const response = await getAllTicket(payload)
        // console.log('response:', response)
        return response.data;
    } catch (e: any) {
        console.error("error from get Ticket Data", e.message)
        if (e?.message === "Request failed with status code 401") {
            alert(e?.response?.data?.message || "Some Technical Issue in Getting Tickets");
            // localStorage.setItem('isLogin', "false");
            return navigate("/login");
        } else {
            throw e;
        }

    }

};