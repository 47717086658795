import React, { useState } from "react";
import style from "./customInput.module.css"

interface CustomInputProps {
  label: string;
  onChange: (updatedField: { label: string; value: string }) => void;
  value: string;
}

const InputType: any = {
  name: "text",
  email: "email",
  PhoneNumber: "number",
  Company: "text",
};

export const CustomInput: React.FC<CustomInputProps> = ({
  label,
  onChange,
  value,
}) => {
  const [isValid, setIsValid] = useState(true);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let updatedValue = e.target.value;
    updatedValue = updatedValue.trim();
    if (label === "mobile") {
      value.replace(/\D/g, "");
      updatedValue = updatedValue.slice(0, 10);
      setIsValid(updatedValue.length === 10);    }
    if (label === "email") {
      const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
      setIsValid(emailRegex.test(updatedValue));
    }
    // if(label==="benId"){      
    //   updatedValue = updatedValue.slice(0, 24); 
    // }
    onChange({ label, value: updatedValue });
  };

  return (
    <>
      <div>
        <div>
          <label>{`${label}`}</label>
        </div>
        <div>
          <input
            className={`${style.input} ${!isValid ? style.invalid_Border : ""}`}
            placeholder={`Enter ${label}`}
            type={InputType[label]}
            value={value}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
};
