// AccountNotFound.js
import { Box, Text } from "@chakra-ui/react";

const AccountNotFound = () => {

  return (
    <Box
      display="flex"
      alignItems="center"
      margin="auto"
      justifyContent="space-between"
    >
      <Box bg="white">
        <Text fontSize="2xl" fontWeight="bold" color="red.500" textAlign="center">
          Account Not Found
        </Text>
        <Text fontSize="lg" textAlign="center">
          Could not find Pococare account. Please try again or contact support.
        </Text>
      </Box>
    </Box>
  );
};

export default AccountNotFound;
