import React, { useEffect, useState } from 'react';
import './App.css';
import { Button, Flex, Image, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import MainRoute from './Routes/MainRoute';
import { handleEmergency } from './Action/hanldeEmergency';
import { useNavigate } from 'react-router-dom';
import ambulanceSound from "./Audio/ambulanceSound.mp3";
import { sseRegister } from './Http';
import { updateTicket } from './Pages/DashBoard/Action/updateTicket';

function App() {
  const [newEmergencyData, setNewEmergencyData] = useState<any>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = React.useRef(null);
  const navigate = useNavigate();
  const [audio] = useState(new Audio(ambulanceSound));
 

  const playAudio = () => {
    audio.play();
  };

  const stopAudio = () => {
    audio.pause();
    audio.currentTime = 0;
  };
  const handleModalClose = () => {
    onClose();
    stopAudio();
  };

  const handleNewEmergencyCase = (data: any) => {
    if (data.ring) {
      playAudio();
    }
    onOpen();
    setNewEmergencyData(data);
  };

  const handleSseMessage = (data: any) => {
    if (data.tag === "NEW_EMERGENCY_CASE") {
      handleNewEmergencyCase(data);
    }

    if (data.tag === "EMERGENCY_HANDLED") {
      handleModalClose();
    }
  };
  const playAudioWithPermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
    } catch (error) {
      console.error("Failed to get audio permission:", error);
    }
  };


  const closeModalRequestFromAllAgents = (id: string) => {
    try {
      handleEmergency(id)
    } catch (e) {
      return alert("some technical issue");
    }
  };
  useEffect(() => {
    // let isLogin = localStorage.getItem("isLogin");
    // if(isLogin==="true"){

      playAudioWithPermission();
      const eventSourceOptions = {
        withCredentials: true, // Include credentials (cookies) with the request
      };
      const source = new EventSource(sseRegister, eventSourceOptions);
      source.addEventListener('NewTicket', (event) => {
  
      });
      source.addEventListener(
        "open",
        () => {
          console.log("SSE opened!");
        },
        false
      );
  
      source.addEventListener(
        "message",
        (e) => {
          handleSseMessage(JSON.parse(e.data));
        },
        false
      );
  
      source.addEventListener(
        "error",
        (e: any) => {
          console.error("Error: ");
        },
        false
      );
      return () => {
        source.close();
      };
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <Flex bg="white" height="100vh">
        <MainRoute />
      </Flex>

      <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"center"} color={"red"}>
            Emergency Alert
          </ModalHeader>
          <ModalBody display={"flex"} flexDir={"column"} alignItems={"center"}>
            <Image
              width={"30%"}
              src="https://em-content.zobj.net/source/animated-noto-color-emoji/356/police-car-light_1f6a8.gif"
            />
            <Text>{newEmergencyData?.body}</Text>
          </ModalBody>

          <ModalFooter display={"flex"} justifyContent={"space-between"}>
            <Button
              colorScheme="red"
              mr={3}
              onClick={() => {
                handleModalClose();
              }}
            >
              Close
            </Button>
            <Button
              colorScheme="green"
              mr={3}
              onClick={async () => {
                handleModalClose();
                closeModalRequestFromAllAgents(newEmergencyData?.tid !== undefined ? newEmergencyData?.tid : "");
                navigate(`/handleEmergency/${newEmergencyData && newEmergencyData.tid}`);
                await updateTicket(newEmergencyData?.tid,{});
                window.location.reload();
              }}
            >
              Handle Emergency
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default App;
