import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
interface TableProps{
    tableHeaders:any[];
    ountBoundCalllDet:any
    storeCallHistoreData:(data:any,mobileNumb:string)=>void
}
export const CustomTabel:React.FC<TableProps> = ({tableHeaders,ountBoundCalllDet,storeCallHistoreData}) => {
    const handleStoreCallDet=(data:any,mobileNum:string)=>{
      storeCallHistoreData(data,mobileNum);
    }
  return (
    <>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
                {
                    tableHeaders.length>0&&tableHeaders.map((el,i)=>{
                        return(
                          <Th key={i}>{el}</Th>
                        )
                    })
                }            
                
            </Tr>
          </Thead>
          <Tbody>
            {ountBoundCalllDet.length>0 && ountBoundCalllDet.map((el:any,i:number)=>{
                return(
                    <Tr onClick={()=>handleStoreCallDet(el,el.callerId.slice(-10))} key={el._id}>
                    <Td>{i+1}</Td>
                    <Td>{el.callerId.slice(-10)}</Td>
                    <Td>{new Date(el.createdAt).toLocaleString("en-GB",{
                        day:"2-digit",
                        month:"2-digit",
                        year:"numeric",
                        hour:"2-digit",
                        minute:"2-digit",
                        hour12:true}

                    )}</Td>
                    <Td >Open</Td>
                  </Tr>
                )
            })

            }
                       
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};
