import { agentPwdReset } from "../../../Http";

export const agentPwdResetAction = async (payload: any, id: string) => {
  try {
    const res = await agentPwdReset(payload, id);
    console.log("res.data:", res.data);
    return res.data;
  } catch (e: any) {
    // return alert(e?.response?.data?.message || "Some Technical Issue");
    throw e;
  }
};
