import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../Pages/DashBoard/Dashboard";
import Ticket from "../Pages/Ticket/Ticket";
import AgentLogin from "../Pages/AgentLogin/AgentLogin";
// import PrivateRoute from "./PrivateRoutes/PrivateRoute";
import ProfilePerk from '../Pages/ProfilePerk/ProfilePerk';
import MedicalPerk from "../Pages/MedicalPerk/MedicalPerk";
import { OutBoundCall } from "../Pages/OutboundCall/OutBoundCall";
import { OutboundCallDetails } from "../Pages/OutboundCallDetail/OutboundCallDetail";
import ResetPwd from "../Pages/ResetPassword/ResetPwd";
import ForgotPwd from "../Pages/ForgotPassword/ForgotPwd";
import AccountNotFound from "../Pages/NotFound/AccountNotFound";

const MainRoute: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path="/handleEmergency/:id" element={<Dashboard />} />
        <Route path="/outboundCall" element={<OutBoundCall />} />
        <Route path="/resetPassword" element={<ResetPwd />} />
        <Route path="/forgotPassword" element={<ForgotPwd />} />
        <Route path="/outboundCallDetail/:num" element={<OutboundCallDetails />} />
        <Route path='profile-perk/:id' element={<ProfilePerk/>}/>
        <Route path='doc-perk/:id' element={<MedicalPerk/>}/>
        <Route path="/login" element={<AgentLogin />} />
        <Route path="/accountNotFound" element={<AccountNotFound />} />
        <Route
          path="/"
          element={
            // <PrivateRoute>
              <Ticket />
            // </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
};

export default MainRoute;
