//eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import medicalStyle from "./MedicalDetails.module.css"
import { BenDataProps, MedicalInfo } from '../../Helper/BeneInterface';
import { Box, SimpleGrid, Text } from '@chakra-ui/react';

const ProfileMedicalDetails: React.FC<{ data: BenDataProps }> = ({ data }) => {
    const [showDetail, setShowDetail] = useState(false);
    let SaSToken = localStorage.getItem("sasToken");
    const { medicalInfo } = data;
    let medicalData: any;
    if (medicalInfo) {
        const {
            currentMedications,
            pastMedicalRecords,
            allergiesToMedications,
            recentHospitalizations,
            chronicConditions,
            generalInfo,
        } = medicalInfo;
                medicalData = [
            {
                title:"Blood Group",
                description:generalInfo?.bloodGroup || "",
            },
            {
                title: "Current Medication",
                description: currentMedications[0]?.description || "",
                link: currentMedications[0]?.docsUrl || "",
            },
            {
                title: "Allergies To Medication",
                description: allergiesToMedications[0]?.description || "",
            },
            {
                title: "Past Medical History",
                description: pastMedicalRecords[0]?.description || "",
                link: pastMedicalRecords[0]?.docsUrl?.length?pastMedicalRecords[0]?.docsUrl.join("") : "",
            },
            {
                title: "Recent Hospitalization",
                description: recentHospitalizations[0]?.description || "",
                link: recentHospitalizations[0]?.doctorsInformation || "",
            },
            {
                title: "Differently Abled",
                description: generalInfo?.differentlyAble || "",

            },
            {
                title: "Chronic Condition",
                description: chronicConditions.join(",") || "",
            },
            {
                title: "Mobility Status",
                description: generalInfo?.mobilityStatus || "",
            },
        ];
    }
    const checkDatesEmpty = (medicalData: MedicalInfo) => {
        if (medicalData.currentMedications.length === 0 && medicalData.recentHospitalizations.length === 0 && medicalData.pastMedicalRecords.length === 0 && medicalData.allergiesToMedications.length === 0 && medicalData.chronicConditions.length === 0 && !medicalData.generalInfo) {
            setShowDetail(true)
        } else {
            setShowDetail(false)
        }
    };
        // here i removed empty dependencies from useEffect if any re-render happen need to change this
    useEffect(() => {
        checkDatesEmpty(medicalInfo);
    });
    console.log('medicalData:', medicalData);
    return (
        <>
            <Box className={medicalStyle.medical_wrapper}>
                <Box className={medicalStyle.medical_container}>
                    <Box>
                        <Text className={medicalStyle.subTitle}>Medical Profile</Text>
                    </Box>
                    {showDetail ? (
                        <Box>
                            <Text>No medical record present</Text>
                        </Box>
                    ) : (
                        <SimpleGrid columns={1} gap={"5px"}>
                            {medicalData.map((item:any, index:number) => {
                                return (
                                    <Box
                                        key={index}
                                        marginTop={"10px"}
                                        className={
                                            (index + 1) % 2 === 0
                                                ? medicalStyle.withBorder
                                                : medicalStyle.withoutBorder
                                        }
                                        display={{ md: "flex", base: "block" }}
                                        justifyContent={"space-between"}
                                    >
                                        <Box display={"flex"} flexDir={"column"} gap={"3px"}>
                                            <Text fontWeight={"600"}>{item.title}</Text>
                                            {item.link ? (
                                                <a
                                                    style={{
                                                        padding: "7px",
                                                        background: "#e2e8f0",
                                                        borderRadius: "8px",
                                                        marginTop: "10px",
                                                        color: "blue",
                                                        fontWeight: "500",
                                                        width: "140px",
                                                    }}
                                                    href={item.link+"?"+SaSToken}
                                                    target="blank"
                                                >
                                                    Open Document
                                                </a>
                                            ) : (
                                                ""
                                            )}
                                        </Box>
                                        <Text
                                            width={"70%"}
                                            textAlign={"left"}
                                            color={"#1C2B2F"}
                                            fontWeight={"500"}
                                        >
                                            {item.description || "No  "}
                                        </Text>
                                    </Box>
                                );
                            })}
                        </SimpleGrid>
                    )}
                </Box>
            </Box>
        </>
    )
}

export default ProfileMedicalDetails
