import { Flex, Box, Tooltip, Icon } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { TbTicket } from "react-icons/tb";
// import { BsQrCode, BsSticky } from "react-icons/bs";
// import { AiOutlinePrinter, AiOutlineNotification } from "react-icons/ai";
// import { BiSupport } from "react-icons/bi";xx
import { BiLogOut } from "react-icons/bi";
import { IconType } from "react-icons";
import { MdLockReset } from "react-icons/md";
import { VscCallOutgoing } from "react-icons/vsc";
import Logout from "../../Pages/Logout/Logout";
interface SidebarItemProps {
  icon: IconType; // Import IconType from 'react-icons' to ensure proper typing
  tooltip: string;
  url: string;
}
const SideBar: React.FC = () => {
  return (
    <>
      <Flex direction="column" bg="gray.200" height="100%" p={"5"}>
        <Logo />
        <SidebarItem icon={TbTicket} tooltip="Tickets" url="/" />
        <SidebarItem
          icon={VscCallOutgoing}
          tooltip="outboundCall"
          url="/outboundCall"
        />
        <SidebarItem
          icon={MdLockReset}
          tooltip="Reset Password"
          url="/resetPassword"
        />
        <SidebarItem icon={BiLogOut} tooltip="Logout" url="" />
        {/* <SidebarItem icon={BsQrCode} tooltip="Generate Org QR" url="/genericQr" />
              <SidebarItem icon={AiOutlinePrinter} tooltip="Print QR" url="/printQr" />
              <SidebarItem icon={BsSticky} tooltip="Print Stickers" url="/printStickers" />
              <SidebarItem icon={AiOutlineNotification} tooltip={"Notification Templates"} url="/notificationTemplate" />
              <SidebarItem icon={BiSupport} tooltip={"Support"} url="/support" /> */}
      </Flex>
    </>
  );
};
const Logo: React.FC = () => {
  return (
    <Box as="button" p={2} mb={4} textAlign="center">
      <img
        src="/Images/transparentLogo.png"
        alt="Logo"
        style={{ width: "34px", height: "34px" }}
      />
    </Box>
  );
};
const SidebarItem: React.FC<SidebarItemProps> = ({ icon, tooltip, url }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    await Logout();
    navigate("/login");
    // window.location.reload();
  };

  return (
    <Tooltip label={tooltip} placement="right">
      <Box as="button" p={2} borderRadius="md" mb={2}>
        <Icon
          onClick={() => (url !== "" ? navigate(url) : handleLogout())}
          as={icon}
          boxSize={6}
        />
      </Box>
    </Tooltip>
  );
};

export default SideBar;
