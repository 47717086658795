import { getTicketById } from "../../../Http"

export const getTicket = async (ticketId: string, navigate:any) => {
  try {
    const response = await getTicketById(ticketId);
    return response;
  } catch (e: any) {
    if (e?.message === "Request failed with status code 401") {
      alert(
        e?.response?.data?.message || "Some Technical Issue in Getting Tickets"
      );
      // localStorage.setItem("isLogin", "false");
      return navigate("/login");
    } else {
      throw e;
    }
  }
};