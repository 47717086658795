import React, { useEffect, useState } from 'react'
import { Box, Skeleton } from '@chakra-ui/react'
import profileStyle from "./medical.module.css"
import { useNavigate, useParams } from 'react-router-dom';
import ProfileBasicDetail from '../../GlobalComponents/BasicDetails/BasicDetails';
import { BenDataProps } from '../../Helper/BeneInterface';
import ProfileMedicalDetails from '../../GlobalComponents/Medical/Medical';
import { getSingleBenDetailsForProfile } from '../ProfilePerk/ProfileAction/ProfileAction';
import { getMedicalDetails } from '../ProfilePerk/ProfileAction/getMedicalDetails';
import { generateSaSToken } from '../../Helper/generateSasToken';


const MedicalPerk: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [benData, setBentData] = useState<BenDataProps | null>(null);

    useEffect(() => {
        const getBeneficiaryData = async () => {
            try{
                generateSaSToken();
                const data = await getSingleBenDetailsForProfile(id || "6530bcdf6e1b0c00f4e7e134");
                const medical = await getMedicalDetails(id || "6530bcdf6e1b0c00f4e7e134");
                setBentData((pre:any)=>{
                    return {...pre, ...data.data, ...medical[0]}
                })
            }catch(e:any){
                alert(e?.response?.data?.message || "Some Technical Issue");
            }
        };
        getBeneficiaryData();
    }, [navigate, id]);
    
    return (
        <>
            <Box className={profileStyle.profile_wrapper}>
                {/* <Box className={profileStyle.profile_sub}> */}

                    <Box className={profileStyle.profile_right}>
                        {benData ? (
                            <Box id="basicInfo" className={profileStyle.benAddressDetails}>
                                <ProfileBasicDetail data={benData} />
                            </Box>
                        ) : (
                            <Box className={profileStyle.benAddressDetails} height={"100%"}>
                                <Skeleton height={"550px"} borderRadius={"20px"} />
                            </Box>
                        )}

                        {benData ? (
                            <Box id="medicalInfo" className={profileStyle.benAddressDetails}>
                                <ProfileMedicalDetails data={benData} />
                            </Box>
                        ) : (
                            ""
                        )}
                    </Box>
                {/* </Box> */}
            </Box>
        </>
    )
}

export default MedicalPerk;
