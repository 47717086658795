import { generateSasToken } from "../Http";


export const generateSaSToken =  async () => {
    // Function to generate and store SAS token
    const generateAndStoreToken = async () => {
        try {
            let sasToken = await generateSasToken(); // Assuming generateSasToken is defined elsewhere
            console.log('sasToken:', sasToken);
            localStorage.setItem("sasToken", sasToken.data.data);
        } catch (error) {
            console.log("SAS token generation error:", error);
        }
    };
    // Call the function immediately and then every 10 seconds
    generateAndStoreToken(); // Initial call
    const intervalId = setInterval(generateAndStoreToken, 25 * 60 * 1000); // Refresh every 10 seconds
    // Return the interval ID so it can be cleared if needed
    return intervalId;
}