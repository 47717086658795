import React, { useState } from "react";
import style from "./NearByHospital.module.css";
import {
  Alert,
  Badge,
  Box,
  Button,
  Checkbox,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import { TicketUpdateInterface } from "../../../../Helper/TicketInterface";
import { Hospital } from "../../../../Helper/HospitalInterface";
import { getDistanceFromLatLonInKm } from "../../../../Helper/findDistance";
interface HospitalProps {
  data:any;
  ticketData: TicketUpdateInterface;
  hospitalLists: Hospital[];
  getHospital: (addressPayload: {
    latitude: number;
    longitude: number;
    radius: number;
  }) => void;
  setTicketData: any;
  preferredHospitalData: any;
  getHospitalContacts:(contact:string)=>void
}
const NearByHospital: React.FC<HospitalProps> = ({
  data,
  ticketData,
  hospitalLists,
  getHospital,
  setTicketData,
  preferredHospitalData,
  getHospitalContacts
}) => {
  const [searchValue, setSearValue] = useState<any>("");
  const [selectedHospital, setSelectedHospital] = useState<Hospital>();
  console.log('hospitalLists:', hospitalLists,data);
  
  // const [esicHospitalSwitch, setEsicHospitalSwitch] = useState(false);
  const handleSearch = () => {
    // console.log('ticketData:', ticketData)
    if (ticketData?.patientLocation?.geoLocation?.coordinates) {
      // console.log('ticketData:', ticketData)
      const payload = {
        latitude: ticketData?.patientLocation?.geoLocation?.coordinates[0],
        longitude: ticketData?.patientLocation?.geoLocation?.coordinates[1],
        radius: Number(searchValue)*1000,
        // isEsic: esicValue,
      };
      getHospital(payload);
    }
    // here no else condition req?
    //once address selected it will from dashboard check weather  the lat and lon is present if yes it will find near hospital
    //if no it will get geoCode and find near by hospital
    //so one possible even geoCode api is not returning lat and lon 😢 ???
  };
    const handleSelectHospital = (el:Hospital) => {
        setSelectedHospital(el);
        createPayloadForHospital(el)
    };
    const getHospitalContact=(contact:any)=>{
      return contact.map((el:any)=>{
        return(
        el.contact
        )
      })

    }
    const createPayloadForHospital = (selectedHospital: any) => {
      // console.log('selectedHospital:', selectedHospital)
      const hospitalContact=getHospitalContact(selectedHospital.contacts)
      getHospitalContacts(hospitalContact?.join(","))
    setTicketData({
      ...ticketData,
      hospitalInfo: {
        hospitalName: selectedHospital.name,
        address: selectedHospital.address,
        contactNumber: hospitalContact?.join(","),
        hospitalContacts:selectedHospital.contacts,
        geoLocation: {
          type: "Point",
            coordinates: [selectedHospital.location.coordinates[1], selectedHospital.location.coordinates[0]],
        },
      },
    });
  };
  return (
    <>
      {ticketData.currentMedicalStatusResponse.isAmbulanceRequired === "Yes" ||
      ticketData.currentMedicalStatusResponse.isAmbulanceRequired === "Yes" ? (
        <Box>
          <Stack
            mt={"1rem"}
            className={style.card}
            direction={"column"}
            justify={"flex-start"}
          >
            <Box>
              <Text className={style.subTitle}>
                Step : Find Near By Hospital
              </Text>
              <Alert
                className={style.prompt_style}
                status="success"
                variant="left-accent"
                mb={".5rem"}
                mt={".5rem"}
              >
                Which hospital would you like us to drop the patient to? I can
                help you with the closest hospitals near your pickup location.
              </Alert>
            </Box>

            <Box display={"flex"} justifyContent={"space-between"}>
              {/* <HStack width={benData?.data?.isEsicUser ? "70%" : ""} gap={"30px"}> */}
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  value={searchValue || ""}
                  onChange={(e: any) => {
                    if (e.target.value > 50) {
                      alert("Select limit within 50Km");
                      setSearValue("");
                    } else {
                      setSearValue(e.target.value);
                    }
                  }}
                  placeholder="Km in numbers (ex:5)"
                />
                <InputRightElement width="4.5rem">
                  <Button
                    onClick={() => {
                      handleSearch();
                      //   benData?.data?.isEsicUser ? handleSearch(esicHospitalSwitch) : handleSearch(false)
                    }}
                    h="1.75rem"
                    size="sm"
                  >
                    Search
                  </Button>
                </InputRightElement>
              </InputGroup>
              {/* {
                                  benData?.data?.isEsicUser ? <FormControl display='flex' alignItems='center'>
                                      <FormLabel color={"blue"} htmlFor='esic-switch' mb='0'>
                                          Only ESIC Hospitals
                                      </FormLabel>
                                      <Switch value={esicHospitalSwitch} onChange={handleEsicHospitalSwitch} id='esic-switch' />
                                  </FormControl> : ""
                              } */}

              {/* </HStack> */}
              <Box></Box>
            </Box>

            <Box className={style.gridSystem}>
              {hospitalLists?.length > 0 &&
                hospitalLists?.map((el: any, i: number) => {
                  const isSelected = selectedHospital === el;

                  const showPreferredHospital =
                    preferredHospitalData[0]?.hospitalName === el.name;
                  const companyName=data.companyName.toLowerCase().includes("tricog")&&el.speciality.includes("cath lab")  
                  console.log('companyName:', companyName);
                  return (
                    <Box key={i} className={style.card}>
                      {showPreferredHospital ? (
                        <Badge ml="1" fontSize="0.8em" colorScheme="green">
                          PreferredHospital
                        </Badge>
                      ) : null}
                      {companyName?<Badge ml="1" fontSize="0.8em" colorScheme="green">Cath Lab</Badge>:null}
                      <Box className={style.flex}>
                        <Text>Name: {el.name}</Text>
                        <Text></Text>
                        <Checkbox
                          marginLeft={"1rem"}
                          onChange={() => handleSelectHospital(el)}
                          isChecked={isSelected}
                        ></Checkbox>
                      </Box>
                      <Box className={style.flex}>
                        <Text>Kilometer:</Text>
                        <Text>
                          {getDistanceFromLatLonInKm(
                            ticketData.patientLocation?.geoLocation?.coordinates
                              ?.length
                              ? ticketData.patientLocation?.geoLocation
                                  ?.coordinates[0]
                              : 0,
                            ticketData.patientLocation?.geoLocation?.coordinates
                              ?.length
                              ? ticketData.patientLocation?.geoLocation
                                  ?.coordinates[1]
                              : 1,
                            el?.location?.coordinates[1],
                            el?.location?.coordinates[0]
                          )}
                        </Text>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </Stack>
        </Box>
      ) : null}
    </>
  );
};

export default NearByHospital;
