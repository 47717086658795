import { updateAgentNotesToCall } from "../../../Http";

export const agentNotes=async (payload:{_id:string,ucid:string,agentNotes:string})=>{

    try{
       const response=await updateAgentNotesToCall(payload._id,payload)
    //    console.log('response:', response);
       return response.data;
    }catch(e){
        throw e;
    }

}